export const environment = {
  production: true,
  localStore:{
    token: 'descarpack-book-gerencial-staging-temp.token',
    user: 'descarpack-book-gerencial-staging-temp.user',
    returnUrl: 'descarpack-book-gerencial-dev.returnUrl',
  },
  urls:{
    api: {
      v1: 'https://descarpack-dre-api.btcd.com.br/v1'
    }
  }
};
