import {Component, EventEmitter, OnInit, Output, ViewChild} from '@angular/core';
import {BaseComponent} from 'src/app/shared/components/base/base.component';
import {ActivatedRoute, Router} from '@angular/router';
import {AppService} from 'src/app/shared/services/app.service';
import {UserService} from 'src/app/shared/services/user.service';
import {UntypedFormGroup, UntypedFormControl, Validators} from '@angular/forms';
import {takeWhile, finalize} from 'rxjs/operators';
import {DialogComponent, TopBarBreadcrumbService} from 'ngx-chameleon';
import {allProfiles, Profile} from 'src/app/dre/enums/profile';
import {VisionService} from '../../../services/vision.service';
import {User} from '../../../models/user';

@Component({
  selector: 'register-user',
  templateUrl: './register-user.component.html',
  styleUrls: ['./register-user.component.css']
})
export class RegisterUserComponent extends BaseComponent implements OnInit {

  constructor(
    private router: Router,
    private topBarBreadcrumbService: TopBarBreadcrumbService,
    private appService: AppService,
    private userService: UserService,
    private visionService: VisionService,
    private activateRoute: ActivatedRoute,
  ) {
    super();
  }

  @ViewChild('dialog', {static: true}) dialog: DialogComponent;
  @Output('on-save') onSave = new EventEmitter<any>();


  profiles = allProfiles;
  profile = Profile;
  visions = [];
  submitted = false;
  id: string;
  isNew = true;
  user: any;
  message = 'Novo';

  form = new UntypedFormGroup({
    email: new UntypedFormControl('', Validators.compose([
      Validators.required,
      Validators.email
    ])),
    visions: new UntypedFormControl([], Validators.required),
    firstName: new UntypedFormControl('', Validators.required),
    lastName: new UntypedFormControl(''),
    profile: new UntypedFormControl('', Validators.required)
  });

  ngOnInit() {
    this.id = this.activateRoute.snapshot.params.id;
    this.listVisions();
  }

  save() {
    this.submitted = true;

    if (this.form.invalid) {
      return;
    }

    this.appService.spinner.show();

    if (this.isNew) {
      this.userService.create(this.form.value).pipe(
        takeWhile(() => this.isAlive),
        finalize(() => this.appService.spinner.hide())
      ).subscribe(resp => {
        this.appService.toastr.success('Usuário criado com sucesso', 'Sucesso');
        this.cancel();
        this.form.reset();
      });
    } else {
      this.userService.edit(this.id, this.form.value).pipe(
        takeWhile(() => this.isAlive),
        finalize(() => this.appService.spinner.hide())
      ).subscribe(resp => {
        this.appService.toastr.success('Usuário editado com sucesso', 'Sucesso');
        this.cancel();
        this.isNew = true;
        this.submitted = false;
        this.form.reset();
        this.onSave.emit();
      });
    }


  }

  appendUser(user: User, isProfile = false) {

    let visions = []

    if(isProfile){
      this.listUser(user.id)
    }else{
      user?.visions?.forEach(vision => {
        visions.push(vision.id)
      });

      if (!!visions)
        this.form.get('visions').patchValue(visions as []);

      this.user = user;
      this.form.get('email').setValue(this.user?.email);
      this.form.get('firstName').setValue(this.user?.firstName);
      this.form.get('lastName').setValue(this.user?.lastName);

      const profile = this.user?.profileDescription === 'Administrador' ? this.profile.Admin : this.profile.Collaborator;
      this.form.get('profile').setValue(profile);
    }

    this.isNew = false;
  }

  listVisions() {

    this.visionService
      .get()
      .pipe(
        takeWhile(() => this.isAlive))
      .subscribe((resp) => {
        this.visions = resp.data.itens;
      });
  }

  listUser(userId: string){

    let params = {id: userId}
    this.userService
      .get(params)
      .pipe(
        takeWhile(() => this.isAlive))
      .subscribe((resp) => {
        this.user = resp.data.itens[0];
        let visions = [];

        this.user?.visions?.forEach(vision => {
          visions.push(vision.id)
        });

          this.form.get('visions').patchValue(visions as []);

        this.form.get('email').setValue(this.user?.email);
        this.form.get('firstName').setValue(this.user?.firstName);
        this.form.get('lastName').setValue(this.user?.lastName);

        const profile = this.user?.profileDescription === 'Administrador' ? this.profile.Admin : this.profile.Collaborator;
        this.form.get('profile').setValue(profile);

      });
  }

  open(user: User = null, isProfile = false) {

    this.isNew = user?.id == null;
    this.id = user?.id;

    this.message = this.isNew ? 'Novo Usuário' : 'Editar Usuário';

    if (!this.isNew) {
      this.appendUser(user, isProfile);

    } else {
      this.form.reset();
    }

    this.dialog.open();
  }

  cancel() {
    this.dialog.close();
  }
}
