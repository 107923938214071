enum Profile {
  Admin = 'Admin',
  Collaborator = 'Collaborator'
}

interface ProfileModel {
  value: string,
  description: string
}

const allProfiles =  [{
    value: 'Admin',
    description: 'Administrador'
  }, {
    value: 'Collaborator',
    description: 'Colaborador'
  }];


export { Profile, ProfileModel, allProfiles }
