enum corFonteEnum {
  Black = 'Preto',
  Blue = 'Azul',
  Purple = 'Roxo',
  Green = 'Verde',
  Brown = 'Marrom',
  Orange = 'Laranja',
  Yellow = 'Amarelo',
  Gray = 'Cinza',
}

const coresFontes = [
  {value: 'Black', label: 'Preto'},
  {value: 'Blue', label: 'Azul'},
  {value: 'Purple', label: 'Roxo'},
  {value: 'Green', label: 'Verde'},
  {value: 'Brown', label: 'Marrom'},
  {value: 'Orange', label: 'Laranja'},
  {value: 'Yellow', label: 'Amarelo'},
  {value: 'Gray', label: 'Cinza'},
];

export { corFonteEnum, coresFontes };
