import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';


@Injectable({
  providedIn: 'root'
})
export class EstruturaConfigRelatoriosService {

  private urls = {
    v1: `${environment.urls.api.v1}/estrutura-configuracao-relatorios`
  }

  constructor(private http: HttpClient) { }

  getWithFilter = (params?: any):Observable<any> => this.http.get<any>(`${this.urls.v1}`, {params: params}).pipe(map(resp => resp));

  get = (id: string):Observable<any> => this.http.get<any>(`${this.urls.v1}/${id}`).pipe(map(resp => resp));

  getContasContabeis = ():Observable<any> => this.http.get<any>(`${this.urls.v1}/contas-contabeis`).pipe(map(resp => resp));

  post = (data: any): Observable<any> => this.http.post(this.urls.v1, data).pipe(map(resp => resp));

  edit = (data: any, id: string):Observable<any> => this.http.patch<any>(`${this.urls.v1}/editar-estrutura/${id}/`, data).pipe(map(resp => resp));

  editParamsDinamics = (data: any, id: string):Observable<any> => this.http.patch<any>(`${this.urls.v1}/editar-parametros-estrutura/${id}`, data).pipe(map(resp => resp));

  remove = (id: string, visionId: string):Observable<any> => this.http.delete<any>(`${this.urls.v1}/${id}/vision/${visionId}`).pipe(map(resp => resp));

}
