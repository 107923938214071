import { Component } from '@angular/core';
import { BaseComponent } from './shared/components/base/base.component';
import { PrimeNGConfig } from 'primeng/api';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent extends BaseComponent {


  constructor(private primengConfig: PrimeNGConfig
    ) {
    super();
  }

  ngOnInit(){
    this.primengConfig.ripple = true;
  }

}
