import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class VisionService {

  private urls = {
    v1: `${environment.urls.api.v1}/visions`
  }

  constructor(private http: HttpClient){

  }

  create = (data: any):Observable<any> => this.http.post<any>(`${this.urls.v1}`, data).pipe(map(resp => resp));

  edit = (id: string, data: any):Observable<any> => this.http.put<any>(`${this.urls.v1}/${id}`, data).pipe(map(resp => resp));

  changeStatus = (id: string):Observable<any> => this.http.patch<any>(`${this.urls.v1}/${id}`, {}).pipe(map(resp => resp));

  remove = (id: string):Observable<any> => this.http.delete<any>(`${this.urls.v1}/${id}`).pipe(map(resp => resp));

  get = (filter?: any):Observable<any> => this.http.get<any>(`${this.urls.v1}`, {params: filter}).pipe(map(resp => resp));

  getById = (id: string):Observable<any> => this.http.get<any>(`${this.urls.v1}/${id}`).pipe(map(resp => resp));


}
