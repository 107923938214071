import {LOCALE_ID, ModuleWithProviders, NgModule} from '@angular/core';
import {CommonModule, DatePipe} from '@angular/common';
import {DateValueAccessor} from './directives/date-value-accessor.directive';
import {NoActionHrefDirective} from './directives/no-action-href.directive';
import {ScrollTopDirective} from './directives/scroll-top.directive';
import {HomeComponent} from './pages/home/home.component';
import {BaseComponent} from './components/base/base.component';
import {GridPaginationComponent} from './components/grid-pagination/grid-pagination.component';
import {GridLoadingComponent} from './components/grid-loading/grid-loading.component';
import {SignedComponent} from './components/signed/signed.component';
import {LoginComponent} from './pages/login/login.component';
import {ListUserComponent} from './pages/users/list-user/list-user.component';
import {RegisterUserComponent} from './pages/users/register-user/register-user.component';
import {ForbiddenComponent} from './pages/forbidden/forbidden.component';
import {PostAuthComponent} from './pages/auth/post-auth/post-auth.component';
import {PostLogoutComponent} from './pages/auth/post-logout/post-logout.component';
import {ListDreLogComponent} from '../dre/pages/relatorios-dres/dre-logs/list-dre-logs/list-dre-log.component';
import {CreateDreLogComponent} from '../dre/pages/relatorios-dres/dre-logs/create-dre-logs/create-dre-log.component';
import {MinusSignToParens} from './pipes/minus-sign-to-parens.pipe';
import {CallbackPipe} from './pipes/callback.pipe';
import {ReportLogComponent} from '../dre/pages/relatorios-dres/dre-logs/report-logs/report-log.component';
import {ConfigReportComponent} from '../dre/pages/relatorios-dres/config-report/config-report.component';
import {ListBatchComponent} from './pages/batches/list-batch/list-batch.component';
import {TransactionsDialogComponent} from './components/transactions-dialog/transactions-dialog.component';
import {BatchCreateComponent} from './components/batch/batch-create/batch-create.component';
import {ListVisionsComponent} from './components/visions/list-visions/list-visions.component';
import {CreateVisionComponent} from './components/visions/create-vision/create-vision.component';
import {
  CellCommentDialogComponent
} from '../dre/components/comentarios-relatorios/cell-comment-dialog/cell-comment-dialog.component';
import {
  AllCommentsThreadsComponent
} from '../dre/components/comentarios-relatorios/all-comments-threads/all-comments-threads.component';
import {ContextMenuComponent} from '../dre/components/comentarios-relatorios/context-menu/context-menu.component';
import {
  ListConfigReportComponent
} from '../dre/pages/relatorios-dres/config-report/list-config-report/list-config-report.component';
import {DialogModalComponent} from './dialog/dialog-modal.component';
import {GridReportLoadingComponent} from './components/grid-report-loading/grid-report-loading.component';
import {UsersReportComponent} from '../dre/pages/relatorios-dres/users-report/users-report.component';
import {ToastModule} from 'primeng/toast';
import {DialogModule} from 'primeng/dialog';
import {ButtonModule} from 'primeng/button';
import {MultiSelectModule} from 'primeng/multiselect';
import {InputTextModule} from 'primeng/inputtext';
import {ContextMenuModule} from 'primeng/contextmenu';
import {TreeTableModule} from 'primeng/treetable';
import {CheckboxModule} from 'primeng/checkbox';
import {FieldsetModule} from 'primeng/fieldset';
import {FormsModule, NgModel, ReactiveFormsModule} from '@angular/forms';
import {HttpClientModule} from '@angular/common/http';
import {RouterModule} from '@angular/router';
import {NgxChameleonModule} from 'ngx-chameleon';
import {NgxSpinnerModule} from 'ngx-spinner';
import {NgSelectModule} from '@ng-select/ng-select';
import {TableModule} from 'primeng/table';
import {DropdownModule} from 'primeng/dropdown';
import {DynamicDialogModule} from 'primeng/dynamicdialog';
import {InplaceModule} from 'primeng/inplace';
import {InputMaskModule} from 'primeng/inputmask';
import {InputSwitchModule} from 'primeng/inputswitch';
import {InputTextareaModule} from 'primeng/inputtextarea';
import {InputNumberModule} from 'primeng/inputnumber';
import {MenuModule} from 'primeng/menu';
import {MenubarModule} from 'primeng/menubar';
import {MessagesModule} from 'primeng/messages';
import {OrderListModule} from 'primeng/orderlist';
import {ProgressBarModule} from 'primeng/progressbar';
import {RadioButtonModule} from 'primeng/radiobutton';
import {RatingModule} from 'primeng/rating';
import {SelectButtonModule} from 'primeng/selectbutton';
import {ToggleButtonModule} from 'primeng/togglebutton';
import {StyleClassModule} from 'primeng/styleclass';
import {CardModule} from 'primeng/card';
import {TreeSelectModule} from 'primeng/treeselect';
import {SplitButtonModule} from 'primeng/splitbutton';
import {NgxPagedListModule} from 'ngx-paged-list';
import {SharedRoutingModule} from "./shared-routing.module";


@NgModule({
  declarations: [
    DateValueAccessor,
    NoActionHrefDirective,
    ScrollTopDirective,
    HomeComponent,
    BaseComponent,
    GridPaginationComponent,
    GridLoadingComponent,
    SignedComponent,
    LoginComponent,
    ListUserComponent,
    RegisterUserComponent,
    ForbiddenComponent,
    PostAuthComponent,
    PostLogoutComponent,
    ListDreLogComponent,
    CreateDreLogComponent,
    MinusSignToParens,
    CallbackPipe,
    DialogModalComponent,
    GridReportLoadingComponent,
    UsersReportComponent

  ],
  exports: [
    SignedComponent,
    ToastModule,
    DialogModule,
    ButtonModule,
    MultiSelectModule,
    InputTextModule,
    ContextMenuModule,
    TreeTableModule,
    CheckboxModule,
    FieldsetModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    RouterModule,
    NgxChameleonModule,
    NgxSpinnerModule,
    NgSelectModule,
    TableModule,
    DropdownModule,
    ButtonModule,
    DynamicDialogModule,
    InplaceModule,
    InputMaskModule,
    InputSwitchModule,
    InputTextModule,
    InputTextareaModule,
    InputNumberModule,
    MenuModule,
    MenubarModule,
    MessagesModule,
    OrderListModule,
    ProgressBarModule,
    RadioButtonModule,
    RatingModule,
    SelectButtonModule,
    ToggleButtonModule,
    StyleClassModule,
    CardModule,
    TreeSelectModule,
    SplitButtonModule,
    NgxPagedListModule,
    GridPaginationComponent,
    GridLoadingComponent,
    MinusSignToParens,
    CallbackPipe,
    GridReportLoadingComponent,
    GridReportLoadingComponent,
    DialogModalComponent
  ],
  imports: [
    ToastModule,
    DialogModule,
    ButtonModule,
    MultiSelectModule,
    InputTextModule,
    ContextMenuModule,
    TreeTableModule,
    CheckboxModule,
    FieldsetModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    RouterModule,
    NgxChameleonModule,
    NgxSpinnerModule,
    NgSelectModule,
    TableModule,
    DropdownModule,
    ButtonModule,
    DynamicDialogModule,
    InplaceModule,
    InputMaskModule,
    InputSwitchModule,
    InputTextModule,
    InputTextareaModule,
    InputNumberModule,
    MenuModule,
    MenubarModule,
    MessagesModule,
    OrderListModule,
    ProgressBarModule,
    RadioButtonModule,
    RatingModule,
    SelectButtonModule,
    ToggleButtonModule,
    StyleClassModule,
    CardModule,
    TreeSelectModule,
    SplitButtonModule,
    NgxPagedListModule,
    SharedRoutingModule
  ]
})
export class SharedModule {

  static forRoot(): ModuleWithProviders<SharedModule> {
    return {
      ngModule: SharedModule,
      providers: [
        DatePipe,
        NgModel,
        {
          provide: LOCALE_ID,
          useValue: 'pt'
        }
      ]
    };
  }
}
