import {Component, OnInit, ViewChild} from '@angular/core';
import {BaseComponent} from "../../base/base.component";
import {PagedListService} from "ngx-paged-list";
import {AlertService, TopBarBreadcrumbService} from "ngx-chameleon";
import {environment} from "../../../../../environments/environment";
import {CreateVisionComponent} from "../create-vision/create-vision.component";
import {finalize, takeWhile} from "rxjs/operators";
import {AppService} from "../../../services/app.service";
import {VisionService} from "../../../services/vision.service";
import {UntypedFormControl, UntypedFormGroup} from "@angular/forms";
import {SessionUser} from "../../../models/session-user";

@Component({
  selector: 'app-list-visions',
  templateUrl: './list-visions.component.html',
  styleUrls: ['./list-visions.component.css']
})
export class ListVisionsComponent extends BaseComponent implements OnInit {

  @ViewChild("criacaoVisao", {static: true})
  criacaoVisao: CreateVisionComponent;

  form = new UntypedFormGroup({
    name: new UntypedFormControl(),
    active: new UntypedFormControl()
  });

  grid: PagedListService;
  status = [{name: 'ativo', id: 'true'}, {name: 'inativo', id: 'false'}];
  user: SessionUser;

  constructor(
    private topBarBreadcrumbService: TopBarBreadcrumbService,
    private alertService: AlertService,
    private appService: AppService,
    private visionService: VisionService) {
    super();
    this.grid = new PagedListService({
      isAlive: this.isAlive,
      pageSize: 10,
      sortField: "Name",
      sortType: "desc",
      url: `${environment.urls.api.v1}/visions`,
    });
  }

  ngOnInit(): void {

    this.user = this.appService.sessionUser;

    this.topBarBreadcrumbService.emmiter.next({
      icon: "fas fa-cogs fa-icon",
      title: "Gerenciamento de Visões",
      path: ["Configurações", "Visões Gerenciais"],
    });

    if(!!this.user){
      let params ={
        userId: this.user?.id
      };
      this.grid.load(params);
    }

  }

  save($event) {
    this.grid.load();
  }

  clear($event){
    this.form.reset()
  }

  openConfig(id?: string) {
    this.criacaoVisao.open(id);
  }

  search($event) {
    const data = { ...this.form.value };
    this.grid.load(data, true);
  }

  updateStatus(id: string, item: any) {
    this.alertService.confirm({
      cancelText: "Não",
      confirmText: "Sim",
      confirmBtnColor: 'red',
      cancelBtnColor: 'gray',
      headerTitle: `Visão ${item.name}`,
      msg: item.active ? 'Tem certeza que deseja inativar essa visão?' : 'Tem certeza que deseja ativar essa visão?',
      confirmFn: () => {
        this.appService.spinner.show();
        this.visionService
          .changeStatus(id)
          .pipe(
            takeWhile(() => this.isAlive),
            finalize(() => this.appService.spinner.hide())
          )
          .subscribe((resp) => {
            this.appService.toastr.success(
              resp.data.active ? 'A visão foi ativada com sucesso!' : 'A visão foi inativada com sucesso!',
              "Sucesso!"
            );
            this.grid.load();
          });
      },
    });
  }

  delete(id: string, name: string) {
    this.alertService.confirm({
      cancelText: "Não",
      confirmText: "Sim",
      headerTitle: `Visão ${name}`,
      confirmBtnColor: 'red',
      cancelBtnColor: 'gray',
      msg: "Tem certeza que deseja excluir essa visão?",
      confirmFn: () => {
        this.appService.spinner.show();
        this.visionService
          .remove(id)
          .pipe(
            takeWhile(() => this.isAlive),
            finalize(() => this.appService.spinner.hide())
          )
          .subscribe((resp) => {
            this.appService.toastr.success(
              "A visão foi removida com sucesso!",
              "Sucesso!"
            );
            this.grid.load();
          });
      },
    });
  }

}
