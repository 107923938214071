<ch-dialog #dialog width="60%" title="<h2> <i class='fal fa-eye icon'></i>&nbsp;{{title}}</h2>" [title-is-html]="true"
           [close-on-click]="false">
<div class="container">
  <form [formGroup]="form" class="ui form">

    <div class="field required m-t-30">
      <label>Nome</label>
      <input type="text" formControlName="name">
      <ch-pointing-label *ngIf="submitted && form.get('name')?.errors?.required">
        Nome não pode ser vazio
      </ch-pointing-label>
    </div>

    <button type="button" class="ui left labeled icon gray button m-r-10" (click)="cancel()">
      <i class="fal cancel icon"></i>
      Cancelar
    </button>

    <button type="button" class="ui left labeled icon teal button" (click)="save()">
      <i class="fal fa-save icon"></i>
      Salvar
    </button>
  </form>

</div>
</ch-dialog>
