import {Component, EventEmitter, OnInit, Output, ViewChild} from '@angular/core';
import {BaseComponent} from '../../base/base.component';
import {DialogComponent} from 'ngx-chameleon';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {AppService} from '../../../services/app.service';
import {finalize, takeWhile} from 'rxjs/operators';
import {VisionService} from '../../../services/vision.service';

@Component({
  selector: 'create-vision',
  templateUrl: './create-vision.component.html',
  styleUrls: ['./create-vision.component.css']
})
export class CreateVisionComponent extends BaseComponent implements OnInit {

  @ViewChild('dialog', {static: true}) dialog: DialogComponent;
  @Output('on-save') onSave = new EventEmitter<any>();

  isEdit = false;
  id: string;
  submitted = false;
  title: string;

  form = new UntypedFormGroup({
    name: new UntypedFormControl(null, [Validators.required])
  });

  constructor(
    private visionService: VisionService,
    private appService: AppService
  ) {
    super();
  }

  ngOnInit(): void {

  }

  open(id?: string) {
    this.dialog.open();

    this.isEdit = !!id;
    this.id = id;

    this.title = this.isEdit ? 'Editar Visão' : 'Nova Visão';

    if (this.isEdit) {
      this.appService.spinner.show();

      this.visionService
        .getById(id)
        .pipe(
          takeWhile(() => this.isAlive),
          finalize(() => this.appService.spinner.hide())
        )
        .subscribe((resp) => {
          this.form.get('name').setValue(resp?.data.name);
        });
    } else {
      this.form.reset()
    }
  }

  save() {
    this.submitted = true;

    this.appService.spinner.show();
    if (this.isEdit) {
      this.visionService
        .edit(this.id, this.form.value)
        .pipe(
          takeWhile(() => this.isAlive),
          finalize(() => this.appService.spinner.hide())
        )
        .subscribe((resp) => {
          this.appService.toastr.success(
            'A visão foi editada com sucesso!',
            'Sucesso!'
          );

          this.form.reset()

          this.onSave.emit(resp);

          this.dialog.close();
        });
    } else {
      this.visionService
        .create(this.form.value)
        .pipe(
          takeWhile(() => this.isAlive),
          finalize(() => this.appService.spinner.hide())
        )
        .subscribe((resp) => {
          this.appService.toastr.success(
            'A visão foi criada com sucesso!',
            'Sucesso!'
          );
          this.form.reset()
          this.onSave.emit(resp);

          this.dialog.close();
        });
    }

  }

  cancel(){
    this.dialog.close()
  }
}
