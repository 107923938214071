<div class="ch-login">
  <div class="ch-login-container">
    <div class="ch-login-header">
      <!-- <div #langDropdwon class="ui floating dropdown labeled search icon button m-t-20 langDropdwon">
        <i class="world icon"></i>
        <span class="text">
          {{'components.signed.topBar.changeLanguage' | translate}}
        </span>
        <div class="menu">
          <ng-container *ngFor="let item of languages">
            <div class="item" [attr.data-value]="item.value" (click)="changeLanguage(item.value)">
              <i [attr.class]="item.flag + ' flag fa-icon'"></i>
              {{item.value}}
            </div>
          </ng-container>
        </div>
      </div> -->
      <img class="logo" src="../../../../assets/images/logo-type.png">

      <h1 class="ui header">
        <span class="ui sub header">
          Faça seu login abaixo
        </span>
      </h1>
    </div>
    <div class="ch-login-content">
      <div class="ch-login-form">
        <form class="ui form" [formGroup]="form">
          <a [ngClass]="{ disabled: !solutionsPortalUrl }" class="ui fluid button" [href]="solutionsPortalUrl">
            <i class="fal fa-sign-in fa-icon"></i>
            Portal de Soluções
          </a>
        </form>
      </div>
    </div>
    <div class="ch-login-footer">

    </div>

    <img class="logo-2" src="">

  </div>
</div>
