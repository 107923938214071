import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AppService } from 'src/app/shared/services/app.service';
import { AuthService } from 'src/app/shared/services/auth.service';

@Component({
  selector: 'app-post-logout',
  templateUrl: './post-logout.component.html',
  styleUrls: ['./post-logout.component.css']
})
export class PostLogoutComponent implements OnInit {
  constructor(
    private router: Router
  ) { }

  ngOnInit() {
    setTimeout(() => this.router.navigate(['/login']), 2000);
  }
}
