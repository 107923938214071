import { Component, OnInit, ViewChild } from "@angular/core";
import { AlertService, DialogComponent, TopBarBreadcrumbService } from "ngx-chameleon";
import { PagedListService } from "ngx-paged-list";
import { BaseComponent } from "src/app/shared/components/base/base.component";
import { BatchCreateComponent } from "src/app/shared/components/batch/batch-create/batch-create.component";
import { AppService } from "src/app/shared/services/app.service";
import { BatchService } from "src/app/dre/services/batch.service";
import { environment } from "src/environments/environment";
import { takeWhile, finalize } from "rxjs/operators";
import {UntypedFormControl, UntypedFormGroup} from "@angular/forms";

@Component({
  selector: "app-list-batch",
  templateUrl: "./list-batch.component.html",
  styleUrls: ["./list-batch.component.css"],
})
export class ListBatchComponent extends BaseComponent implements OnInit {
  @ViewChild("batchCreate", { static: true }) batchCreate: BatchCreateComponent;

  grid: PagedListService;
  form = new UntypedFormGroup({
    nome: new UntypedFormControl(null)
  });

  constructor(
    private topBarBreadcrumbService: TopBarBreadcrumbService,
    private batchService: BatchService,
    private appService: AppService,
    private alertService: AlertService
  ) {
    super();

    this.grid = new PagedListService({
      isAlive: this.isAlive,
      pageSize: 10,
      sortField: "CriadoEm",
      sortType: "desc",
      url: `${environment.urls.api.v1}/batches`,
    });
  }

  ngOnInit(): void {
    this.topBarBreadcrumbService.emmiter.next({
      icon: "fas fa-cogs fa-icon",
      title: "Lista Lotes",
      path: ["Configurações", "Lotes"],
    });

    this.grid.load();
  }

  edit(item) {
    this.batchCreate.open(item);
    this.grid.load();
  }

  remove(id: string, name: string) {

    this.alertService.confirm({
      cancelText: "Não",
      confirmText: "Sim",
      headerTitle: `Lote ${name + 1}`,
      confirmBtnColor: 'red',
      cancelBtnColor: 'gray',
      msg: "Tem certeza que deseja excluir o Lote?",
      confirmFn: () => {
        this.appService.spinner.show();
        this.batchService
        .remove(id)
        .pipe(
          takeWhile(() => this.isAlive),
          finalize(() => this.appService.spinner.hide())
        )
        .subscribe((resp) => {
          this.appService.toastr.success(
            "O lote foi removido com sucesso!",
            "Sucesso!"
          );
          this.grid.load();
        });
      },
    });



  }

  create() {
    this.batchCreate.open();
  }

  onSaveBatch() {
    this.grid.load();
  }


  search($event) {
    this.grid.load({ ...this.form.value }, true);
  }

  clear($event) {
    this.appService.forms.clear(this.form);
    this.grid.load({}, false);
  }

}
