<ch-dialog #dialog width="60%" title="<h2> <i class='fal fa-user icon'></i>&nbsp;{{message}}</h2>"
           [title-is-html]="true"
           [close-on-click]="false">
  <form class="ui form" [formGroup]="form">
    <div class="field required m-t-30" [ngClass]="{'error': form.controls.email.invalid && submitted}">
      <label>
        E-mail
      </label>
      <input type="text" required formControlName="email">
      <ch-pointing-label *ngIf="submitted && form.controls.email?.errors?.required">
        E-mail é obrigatório
      </ch-pointing-label>
      <ch-pointing-label class="error" *ngIf="submitted && form.controls.email?.errors?.email">
        E-mail é inválido
      </ch-pointing-label>
    </div>

    <div class="field required" [ngClass]="{'error': form.controls.firstName.invalid && submitted}">
      <label>
        Nome
      </label>
      <input type="text" required formControlName="firstName">
      <ch-pointing-label *ngIf="submitted && form.controls.firstName?.errors?.required">
        Nome é obrigatório
      </ch-pointing-label>
    </div>

    <div class="field">
      <label>
        Sobrenome
      </label>
      <input type="text" formControlName="lastName">
    </div>

    <div class="field required" [ngClass]="{'error': form.controls.profile.invalid && submitted}">
      <label>
        Perfil
      </label>

      <ng-select
        [items]="profiles"
        bindValue="value"
        bindLabel="description"
        formControlName="profile"
      >
      </ng-select>
      <ch-pointing-label *ngIf="submitted && form.controls.profile?.errors?.required">
        Perfil é obrigatório
      </ch-pointing-label>
    </div>

    <div class="field required" [ngClass]="{'error': form.controls.visions.invalid && submitted}">
      <label>Visões Gerenciais</label>
      <ng-select
        [items]="visions"
        bindValue="id"
        bindLabel="name"
        formControlName="visions"
        appendTo="body"
        [multiple]="true"
      >
      </ng-select>

      <ch-pointing-label *ngIf="submitted && form.controls.visions?.errors?.required">
        Visão gerencial é obrigatória
      </ch-pointing-label>
    </div>

    <button type="button" class="ui left labeled icon gray button m-r-10" (click)="cancel()">
      <i class="fal cancel icon"></i>
      Cancelar
    </button>

    <button type="button" class="ui left labeled icon teal button" (click)="save()">
      <i class="fal fa-save icon"></i>
      Salvar
    </button>
  </form>


</ch-dialog>
