<ch-dialog-modal
  #dialog
  width="60%"
  title="<h2> <i class='fal fa-list icon'></i>&nbsp;{{ titulo }}</h2>"
  [title-is-html]="true"
  [close-on-click]="false"
>
  <div class="container">
    <form [formGroup]="form" class="ui form m-t-20">
      <div class="fields">
        <div class="twelve wide field required">
          <label>Nome</label>
          <input type="text" formControlName="nome" />
          <ch-pointing-label
            *ngIf="submitted && form.get('nome')?.errors?.required"
          >
            É necessário preencher o nome da linha.
          </ch-pointing-label>

          <ch-pointing-label
            *ngIf="submitted && form.get('nome')?.value?.length > 55"
          >
            É permitido no máximo 55 caracteres.
          </ch-pointing-label>

          <ch-pointing-label
            *ngIf="
              listaNomes.includes(nameToUpperCase(this.form.get('nome').value))
            "
          >
            Já existe uma linha com esse nome.
          </ch-pointing-label>
        </div>
      </div>

      <div class="four fields">
        <div class="field">
          <label>Tamanho da Fonte</label>
          <ng-select
            [items]="tamanhoFonte"
            bindValue="value"
            bindLabel="label"
            formControlName="tipoFonte"
            appendTo="body"
          >
          </ng-select>
        </div>
        <div class="field">
          <label>Fonte Maiúscula</label>
          <ng-select
            [items]="statuses"
            bindValue="value"
            bindLabel="label"
            formControlName="maiuscula"
            appendTo="body"
          >
          </ng-select>
        </div>
        <div class="field">
          <label>Cor Fonte</label>
          <select
            [style]="'background-color:' + form.value.corFonte"
            formControlName="corFonte"
            [ngStyle]="{
              color: form.value.corFonte == 'Black' ? 'white' : 'black'
            }"
            name="corFonte"
          >
            <option
              *ngFor="let item of cores"
              [value]="item.value"
              [style]="'background-color:' + item.value"
              [ngStyle]="{ color: item.value == 'Black' ? 'white' : 'black' }"
            >
              {{ item.label }}
            </option>
          </select>
        </div>

      </div>

      <div class="four fields">
        
        <div class="field">
          <label>Negrito</label>
          <ng-select
            [items]="statuses"
            bindValue="value"
            bindLabel="label"
            formControlName="negrito"
            appendTo="body"
          >
          </ng-select>
        </div>
        <div class="field">
          <label>Tab à direita</label>
          <ng-select
            [items]="statuses"
            bindValue="value"
            bindLabel="label"
            formControlName="recuoDireita"
            appendTo="body"
          >
          </ng-select>
        </div>

        <div class="field">
          <label>Divisor</label>
          <ng-select
            [items]="statuses"
            ngDefaultControl
            bindValue="value"
            bindLabel="label"
            formControlName="possuiDivisor"
            appendTo="body"
          >
          </ng-select>
        </div>
        
      </div>
      <div class="four fields">
        <div class="field">
          <label>Cálculo</label>
          <ng-select
            (change)="onModelChange($event)"
            [items]="statuses"
            ngDefaultControl
            bindValue="value"
            bindLabel="label"
            formControlName="calculo"
            appendTo="body"
          >
          </ng-select>
        </div>
      </div>

      <div *ngIf="!calculo" class="two fields">
        <div class="twelve wide field">
          <h2 class="m-t-15">
            Contas Contábeis
            <a class="ui circular label">{{
              form.get("contasContabeisRelatoriosSinteticos").value?.length
            }}</a>
          </h2>

          <div class="input-field field">
            <div class="field card flex justify-content-center">
              <label>Contas Contábeis</label>
              <p-treeSelect
                [filterInputAutoFocus]="true"
                [filter]="true"
                [metaKeySelection]="false"
                [propagateSelectionUp]="false"
                [options]="contas"
                appendTo="body"
                display="chip"
                formControlName="contasContabeisRelatoriosSinteticos"
                placeholder="Selecione uma conta"
                selectionMode="checkbox"
              >
              </p-treeSelect>
              <ch-pointing-label
                *ngIf="
                  submitted &&
                  form.controls['contasContabeisRelatoriosSinteticos']?.errors
                    ?.required
                "
              >
                É necessário preencher as contas contábeis.
              </ch-pointing-label>
            </div>
          </div>
        </div>
      </div>

      <div *ngIf="calculo" class="m-r-40">
        <div class="ui toggle checkbox m-b-20 m-t-30 responsivel-toggle">
          <input type="checkbox" name="public" formControlName="porcentagem" />
          <label>É porcentagem?</label>
        </div>

        <div class="required field m-r-30">
          <label>Expressão</label>
          <textarea
            formControlName="expressao"
            class="field container-celula required m-r-40 m-b-10"
            style="font-size: larger"
          >
          </textarea>
          <ch-pointing-label
            *ngIf="
              submitted &&
              !handleNullExpression(this.form.get('expressao').value)
            "
          >
            É necessário preencher a expressão.
          </ch-pointing-label>
          <ch-pointing-label
            *ngIf="
              submitted && !handleExpression(this.form.get('expressao').value)
            "
          >
            A expressão informada é inválida.
          </ch-pointing-label>
          <ch-pointing-label
            *ngIf="
              submitted &&
              !handleDoubleOperator(this.form.get('expressao').value)
            "
          >
            Existem operadores em sequência
          </ch-pointing-label>
          <ch-pointing-label
            *ngIf="
              submitted && !handleAnyOperator(this.form.get('expressao').value)
            "
          >
            não existem nenhum operador
          </ch-pointing-label>
          <ch-pointing-label
            *ngIf="
              submitted && !handleDoubleValue(this.form.get('expressao').value)
            "
          >
            não existem valores em sequência
          </ch-pointing-label>
          <ch-pointing-label
            *ngIf="
              submitted && !handleAnyValue(this.form.get('expressao').value)
            "
          >
            não existem nenhum valor
          </ch-pointing-label>
          <ch-pointing-label
            *ngIf="
              submitted &&
              !handleInvalidSimpleExpression(this.form.get('expressao').value)
            "
          >
            existem operadores sem valores entre ele
          </ch-pointing-label>
          <ch-pointing-label
            *ngIf="
              submitted && !handleEndOperator(this.form.get('expressao').value)
            "
          >
            A expressão não pode terminar com um operador
          </ch-pointing-label>
          <ch-pointing-label
            *ngIf="
              submitted && !handleStartValue(this.form.get('expressao').value)
            "
          >
            A expressão deve começar com um valor
          </ch-pointing-label>
        </div>

        <div class="two fields m-r-30">
          <button class="ui teal button" (click)="handleSign('plus')">+</button>
          <button class="ui teal button" (click)="handleSign('minus')">
            -
          </button>
          <button class="ui teal button" (click)="handleSign('mult')">*</button>
          <button class="ui teal button" (click)="handleSign('div')">/</button>
          <button class="ui teal button m-l-20" (click)="handleSign('open')">
            (
          </button>
          <button class="ui teal button" (click)="handleSign('close')">
            )
          </button>
          <button class="ui teal button m-l-20" (click)="handleSign('module')">
            Módulo
          </button>
          <button class="ui red button m-l-20" (click)="handleSign('clean')">
            Limpar
          </button>
        </div>

        <label>Linhas </label>
        <div class="container-celula m-b-10" style="font-size: larger">
          <div *ngFor="let secao of secoes; let i = index">
            <button
              class="ui label"
              id="button-list"
              (click)="handleElement(secao?.nome)"
            >
              {{ secao?.nome }}
            </button>
          </div>
        </div>
      </div>

      <div class="ui clearing basic segment m-t-30 m-b-10">
        <button
          type="button"
          class="ui left labeled icon gray button m-r-10"
          (click)="cancel()"
        >
          <i class="fal cancel icon"></i>
          Cancelar
        </button>

        <button
          type="button"
          class="ui left labeled icon teal button"
          (click)="save()"
        >
          <i class="fal fa-save icon"></i>
          Salvar
        </button>
      </div>
    </form>
  </div>
</ch-dialog-modal>
