import {
  Component,
  EventEmitter,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { DialogComponent } from "ngx-chameleon";
import { AppService } from "src/app/shared/services/app.service";
import { BatchService } from "src/app/dre/services/batch.service";
import { BaseComponent } from "../../base/base.component";
import { takeWhile, finalize } from "rxjs/operators";
@Component({
  selector: "batch-create",
  templateUrl: "./batch-create.component.html",
  styleUrls: ["./batch-create.component.css"],
})
export class BatchCreateComponent extends BaseComponent implements OnInit {
  @ViewChild("dialog", { static: true }) dialog: DialogComponent;
  @Output("on-save") onSave = new EventEmitter<any>();

  formBatch: UntypedFormGroup;
  submitted = false;
  title: string;
  constructor(
    private fb: UntypedFormBuilder,
    private batchservice: BatchService,
    private appService: AppService
  ) {
    super();
  }


  ngOnInit(): void {
    this.formBatch = this.fb.group({
      nome: this.fb.control(null, Validators.required),
      ano: this.fb.control(null, Validators.required),
    });
  }

  form() {
    this.formBatch = this.fb.group({
      id: this.fb.control(null),
      nome: this.fb.control(null, Validators.required),
      ano: this.fb.control(null, Validators.required),
    });
  }

  open(item: any = null) {
    this.form();

    this.title = item?.id ? 'Editar Lote' : 'Novo Lote';

    if (item != null) {
      this.formBatch.patchValue(item);
    }

    this.dialog.open();
  }

  save() {
    this.submitted = true;

    if (this.formBatch.invalid) return;

    const cmd = { ...this.formBatch.value };

    if (cmd.id != null) {

      this.batchservice
      .edit(cmd)
      .pipe(
        takeWhile(() => this.isAlive),
        finalize(() => this.appService.spinner.hide())
      )
      .subscribe((resp) => {
        this.appService.toastr.success(
          "Os dados foram salvos com sucesso!",
          "Sucesso!"
        );

        this.onSave.emit();

      });
      this.dialog.close();
    }
    else{
      this.batchservice
        .create(cmd)
        .pipe(
          takeWhile(() => this.isAlive),
          finalize(() => this.appService.spinner.hide())
        )
        .subscribe((resp) => {
          this.appService.toastr.success(
            "Os dados foram salvos com sucesso!",
            "Sucesso!"
          );
          this.onSave.emit();

        });
        this.dialog.close();
    }
  }

  cancel(){
    this.dialog.close()
  }
}
