<ch-dialog-modal
  #dialog
  width="60%"
  title="<h2> <i class='fal fa-list icon'></i>&nbsp;{{ titulo }}</h2>"
  [title-is-html]="true"
  [close-on-click]="false"
>
  <div class="container container-accouting">
    <div class="ui list">
      <div 
        *ngFor="let item of secao?.contasContabeisRelatoriosSinteticos"
        class="item content--font-size">
          {{item?.label}}
      </div>
    </div>
  </div>
</ch-dialog-modal>
