<signed></signed>

<div class="ui padded grid stackable">
  <div class="main-content">
    <div class="m-b-10">
      <div
        ch-top-bar-breadcrumb
        style="margin-bottom: 5px; display: inline-block"
      ></div>

      <div style="display: inline-block; float: right">
        <button
          (click)="openConfig()"
          style="float: end"
          type="button"
          class="ui left labeled icon teal button"
        >
          <i class="fal fa-plus icon"></i>
          Novo
        </button>
      </div>
    </div>

    <p-table
      (onRowReorder)="atualizaOrdenacao($event)"
      [value]="secoes"
      [columns]="cols"
      [reorderableColumns]="true"
      dataKey="id"
      [scrollable]="true"
      scrollHeight="calc(100vh - 140px)"
    >
      <ng-template pTemplate="header" let-columns>
        <tr>
          <th
            *ngFor="let col of columns"
            pReorderableColumn
            [ngStyle]="{ width: col.field === 'id' ? '10%' : '50%' }"
          >
            {{ col.header }}
          </th>
        </tr>
      </ng-template>

      <ng-template
        pTemplate="body"
        let-rowData
        let-columns="columns"
        let-index="rowIndex"
        let-secao
      >
        <tr [pReorderableRow]="index">
          <td *ngFor="let col of columns">
            <div
              *ngIf="col.field === 'id'"
              [attr.data-info]="'Opções'"
              ch-dp-btn
              color="tiny basic"
            >
              <div class="header">Opções</div>

              <div class="item" (click)="openConfig(rowData[col.field])">
                <span class="ui tiny blue header">
                  <i class="fas fa-edit fa-icon"></i>
                </span>
                Editar
              </div>
              <div *ngIf="temContas(rowData[col.field])" class="item" (click)="verContas(rowData[col.field])">
                <span class="ui tiny blue header">
                  <i class="fas fa-eye fa-icon"></i>
                </span>
                Ver contas
              </div>
              <div *ngIf="!temContas(rowData[col.field])" class="item" (click)="verCalculo(rowData[col.field])">
                <span class="ui tiny blue header">
                  <i class="fas fa-eye fa-icon"></i>
                </span>
                Ver Cálculo
              </div>

              <div class="divider"></div>

              <div class="item" (click)="remove(rowData[col.field], index)">
                <span class="ui tiny red header">
                  <i class="fas fa-trash fa-icon"></i>
                </span>
                Deletar
              </div>
            </div>

            <div
              *ngIf="col.field !== 'id'"
              [ngStyle]="{
                'text-align': col.field == 'ordenacao' ? 'right' : 'left'
              }"
            >
              <span
                *ngIf="col.field == 'ordenacao'"
                class="pi pi-bars"
                pReorderableRowHandle
              >
                &nbsp;
              </span>

              <span
                style="
                  overflow-wrap: break-word;
                  word-wrap: break-word;
                  word-break: break-word;
                "
                *ngIf="
                  col.field != 'id' &&
                  !!secao
                "
              >
                <span>
                  {{
                    rowData[col.field].toString() == "true" ||
                    rowData[col.field].toString() == "false"
                      ? rowData[col.field]
                        ? "Sim"
                        : "Não"
                      : rowData[col.field]
                  }}
                </span>
              </span>
            </div>
          </td>
        </tr>
      </ng-template>
    </p-table>
  </div>
</div>
<config-report #configReportSynthetic (on-save)="save($event)"></config-report>
<accounting-account-list #accountingCcountList ></accounting-account-list>
<accounting-calc-view #accountingCalcView ></accounting-calc-view>
