import {
  Component,
  EventEmitter, Input,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import { BaseComponent } from "src/app/shared/components/base/base.component";
import { takeWhile, finalize } from "rxjs/operators";
import { UntypedFormControl, UntypedFormGroup, Validators } from "@angular/forms";
import { AppService } from "src/app/shared/services/app.service";
import { EstruturaConfigRelatoriosService } from "../../../services/estrutura-config-relatorios.service";
import { DialogModalComponent } from "../../../../shared/dialog/dialog-modal.component";
import { tiposFontes } from "../../../enums/tipoFonteEnum";
import { coresFontes } from "../../../enums/corFonteEnum";

@Component({
  selector: "config-report",
  templateUrl: "./config-report.component.html",
  styleUrls: ["./config-report.component.css"],
})
export class ConfigReportComponent
  extends BaseComponent
  implements OnInit {
  @ViewChild("dialog", { static: true }) dialog: DialogModalComponent;
  @Output("on-save") onSave = new EventEmitter<any>();
  @Output("on-close") onClose = new EventEmitter<any>();


  id: string;
  submitted = false;
  contas: any;
  codigoNome = [];
  labels: any = [];
  calculo = false;
  visionId: string;

  titulo: string;

  cores: any = coresFontes;

  secoes: any;

  statuses = [
    { label: 'Sim', value: true },
    { label: 'Não', value: false }
  ];

  tamanhoFonte = tiposFontes;

  listaNomes = [];

  estrutura: any;

  edit = false;

  form = new UntypedFormGroup({
    nome: new UntypedFormControl('', Validators.required),
    negrito: new UntypedFormControl(this.statuses[1].value),
    corFonte: new UntypedFormControl(coresFontes[0].value),
    tipoFonte: new UntypedFormControl(tiposFontes[1].value),
    maiuscula: new UntypedFormControl(this.statuses[1].value),
    recuoDireita: new UntypedFormControl(this.statuses[1].value),
    calculo: new UntypedFormControl(this.statuses[1].value),
    porcentagem: new UntypedFormControl(this.statuses[1].value),
    possuiDivisor: new UntypedFormControl(this.statuses[1].value),
    expressao: new UntypedFormControl(null),
    visionId: new UntypedFormControl(''),
    contasContabeisRelatoriosSinteticos: new UntypedFormControl([], Validators.required)
  });



  constructor(
    private estruturaConfigRelatoriosService: EstruturaConfigRelatoriosService,
    private appService: AppService
  ) {
    super();

  }

  ngOnInit(): void {

  }


  onModelChange(event) {
    this.calculo = event.value;
    var contasControl = this.form.get("contasContabeisRelatoriosSinteticos")
    if (this.calculo) {
      contasControl.clearValidators();
    }
    else {
      contasControl.addValidators(Validators.required)
    }
    contasControl.updateValueAndValidity();
  }

  nameToUpperCase(name: string) {

    return name?.toUpperCase();
  }

  open(secoes, visionId, id = null) {

    this.secoes = secoes.filter(s => !s.nome.includes('%'));

    this.secoes?.forEach(secao => {
      this.listaNomes.push(secao.nome.toUpperCase());
    });

    this.visionId = visionId;

    this.appService.spinner.show();

    this.estruturaConfigRelatoriosService
      .getContasContabeis()
      .pipe(
        takeWhile(() => this.isAlive),
        finalize(() => this.appService.spinner.hide())
      )
      .subscribe((resp) => {
        this.contas = resp.data;

      });
    this.codigoNome = [];
    if (!!id) {
      this.id = id;
      this.titulo = 'Editar Linha';
      this.edit = true;
      this.obtemEstrutura(id);
    } else {
      this.titulo = 'Adicionar Linha';
      this.edit = false;
      this.form.patchValue({
        negrito: this.statuses[1].value,
        corFonte: coresFontes[0].value,
        tipoFonte: tiposFontes[1].value,
        maiuscula: this.statuses[1].value,
        recuoDireita: this.statuses[1].value,
        calculo: this.statuses[1].value,
        porcentagem: this.statuses[1].value,
        possuiDivisor: this.statuses[1].value,
      })
    }

    this.dialog.open();

    
  }



  cancel() {
    this.form.reset();
    this.dialog.close();
  }

  save() {
    this.submitted = true;

    let possuiExpressao = true;

    if (this.form.get('calculo').value)
      possuiExpressao = this.handleExpression(this.form.get('expressao').value) &&
        this.handleNullExpression(this.form.get('expressao').value);

    if (this.form.invalid || !possuiExpressao || this.listaNomes.includes(this.nameToUpperCase(this.form.get('nome').value)) || this.form.get('nome')?.value?.length > 55)
      return;

    this.form.get('visionId').setValue(this.visionId);

    if (this.edit) {
      this.onRowEditSave(this.form.value, this.id);
    } else {
      this.appService.spinner.show();
      const cmd = {
        ...this.form.value
      };

      if (!possuiExpressao) {
        cmd.contasContabeisRelatoriosSinteticos.forEach((element) => {
          if (!!element.label)
            this.labels.push({ label: element.label, key: element.key });
        });

        cmd.contasContabeisRelatoriosSinteticos = this.labels;
      }

      this.estruturaConfigRelatoriosService
        .post(cmd)
        .pipe(
          takeWhile(() => this.isAlive),
          finalize(() => this.appService.spinner.hide())
        )
        .subscribe((resp) => {
          this.appService.toastr.success(
            'Estrutura criada com sucesso!',
            'Sucesso!'
          );
          this.onSave.emit(resp);
          this.submitted = false;

          this.dialog.close();
        });
    }
  }

  builtExpression(expression: string){
    //verificacao de modulo
    let arr = Array.from(expression);
    if (expression?.includes('|') && arr.filter(p => p === '|')?.length % 2 === 0) {
      expression = expression?.replaceAll('|', '');
    }

    //verificacao de linhas
    this.secoes?.forEach(secao => {
      if (expression?.toUpperCase().includes(secao.nome.toUpperCase())) {
        const regex = new RegExp(secao.nome, 'g');
        expression = expression?.replace(regex, '1');
      }
    });

    return expression;
  }

  handleDoubleOperator(expression: string): boolean {
    if (expression) {
      var regex = /[+\-*/][+\-*/]/;
      var ex = this.builtExpression(expression)
      return !regex.test(ex.split(" ").join(""));
    }
    else return true
  }

  handleEndOperator(expression: string): boolean {
    if (expression) {
      var regex = /[-+*/]\s*$/;
      var ex = this.builtExpression(expression)
      return !regex.test(ex.split(" ").join(""));
    }
    else return true
  }
  handleStartValue(expression: string): boolean {
    if (expression) {
      var regex = /^1/;
      var ex = this.builtExpression(expression)
      return regex.test(ex.split(" ").join(""));
    }
    else return true
  }

  handleInvalidSimpleExpression(expression: string): boolean {
    if (expression) {
      var regex = /\b\d+\s*[-+*/]\s*\d+\b|\b[-+*/]\s*\d+\b|\b\d+\s*[-+*/]\b/;
      var ex = this.builtExpression(expression)
      ex = ex.split("(").join("")
      ex = ex.split(")").join("")
      return regex.test(ex.split(" ").join(""));
    }
    else return true
  }


  handleDoubleValue(expression: string): boolean {
    if (expression) {
      var regex = /1\s*1/;
      var ex = this.builtExpression(expression)
      return !regex.test(ex.split(" ").join(""));
    }
    else return true
  }

  handleAnyValue(expression: string): boolean {
    if (expression) {
      var regex = /1/;
      var ex = this.builtExpression(expression)
      return regex.test(ex.split(" ").join(""));
    }
    else return true
  }

  handleAnyOperator(expression: string): boolean {
    if (expression) {
      var regex = /[+\-*/]/;
      var ex = this.builtExpression(expression)
      return regex.test(ex.split(" ").join(""));
    }
    else return true

  }

  handleExpression(expression: any): boolean {

    if (expression != null) {
      try {
        eval(this.builtExpression(expression));
        return true;
      } catch {
        return false;
      }
    }
  }

  handleNullExpression(expression: string): boolean {
    return !!expression;
  }

  handleElement(secaoNome: string) {
    const formExpressao = this.form.get('expressao').value ?? '';

    this.form.get('expressao').setValue(formExpressao + ' ' + secaoNome);
  }

  handleSign(sign: string) {
    const value = this.form.get('expressao').value ?? ' ';

    switch (sign) {
      case 'plus':
        this.form.get('expressao').setValue(value + ' +');
        break;
      case 'minus':
        this.form.get('expressao').setValue(value + ' -');
        break;
      case 'mult':
        this.form.get('expressao').setValue(value + ' *');
        break;
      case 'div':
        this.form.get('expressao').setValue(value + ' /');
        break;
      case 'open':
        this.form.get('expressao').setValue(value + '(');
        break;
      case 'close':
        this.form.get('expressao').setValue(value + ')');
        break;
      case 'module':
        this.form.get('expressao').setValue(value + ' |');
        break;
      case 'clean':
        this.form.get('expressao').reset();
        break;
      default:
        break;
    }
  }

  obtemEstrutura(id: string) {

    this.estruturaConfigRelatoriosService
      .get(id)
      .pipe(
        finalize(() => this.appService.spinner.hide())
      )
      .subscribe((resp) => {

        this.estrutura = resp.data;

        this.listaNomes?.forEach(secao => {

          if (secao === this.estrutura?.nome?.toUpperCase())
            this.listaNomes.splice(this.listaNomes.indexOf(secao), 1);
        });


        if (this.estrutura?.contasContabeisRelatoriosSinteticos?.length > 0)
          this.form.get('contasContabeisRelatoriosSinteticos').setValue(this.estrutura?.contasContabeisRelatoriosSinteticos);

        this.form.get('nome').setValue(this.estrutura?.nome);
        this.form.get('negrito').setValue(this.estrutura?.negrito);
        this.form.get('corFonte').setValue(this.estrutura?.corFonte);
        this.form.get('tipoFonte').setValue(this.estrutura?.tipoFonte);

        this.form.get('maiuscula').setValue(this.estrutura?.maiuscula);
        this.form.get('recuoDireita').setValue(this.estrutura?.recuoDireita);
        this.form.get('calculo').setValue(this.estrutura?.calculo);
        this.calculo = this.estrutura?.calculo;
        this.form.get('porcentagem').setValue(this.estrutura?.porcentagem);

        this.form.get('possuiDivisor').setValue(this.estrutura?.possuiDivisor);

        this.form.get('expressao').setValue(this.estrutura?.expressao);
        this.form.get('visionId').setValue(this.estrutura?.visionId);
      });

  }

  onRowEditSave(secao: any, id: string) {
    this.appService.spinner.show();

    let cmd: any = secao;

    cmd.contasContabeisRelatoriosSinteticos.forEach((element) => {
      if (!!element.label)
        this.labels.push({ label: element.label, key: element.key });
    });

    cmd.contasContabeisRelatoriosSinteticos = !this.form.value?.calculo ? this.labels : null;
    cmd.expressao = this.form.value?.calculo ? this.form.value?.expressao : null;

    this.estruturaConfigRelatoriosService
      .edit(cmd, id)
      .pipe(
        finalize(() => this.appService.spinner.hide())
      )
      .subscribe((resp) => {
        this.appService.toastr.success(
          'Estrutura editada com sucesso!',
          'Sucesso!'
        );

        this.onSave.emit(resp);
        this.submitted = false;
        this.dialog.close();
      });
  }
}
