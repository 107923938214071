import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';

const routes: Routes = [
    {
      path: '',
      loadChildren: () =>
        import('../app/dre/dre.module').then(
          (m) => m.DreModule
        ),
    },
    {
      path: 'projecao-demanda',
      loadChildren: () =>
        import('../app/projecao-demanda/projecao-demanda.module').then(
          (m) => m.ProjecaoDemandaModule
        ),
    },
    {
      path: 'shared',
      loadChildren: () =>
        import('../app/shared/shared.module').then(
          (m) => m.SharedModule
        ),
    },
  ]
;

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
