 enum tipoFonteEnum {
  Medium = 'Média',
  Large = 'Grande'
}

 const tiposFontes = [
  {value: 'Medium', label: 'Média'},
  {value: 'Large', label: 'Grande'}
];

 export { tipoFonteEnum, tiposFontes };
