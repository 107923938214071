import {AfterViewInit, Component, EventEmitter, Input, Output, ViewChild} from '@angular/core';
import {AppService} from 'src/app/shared/services/app.service';
import {BaseComponent} from '../base/base.component';
import {SessionUser} from 'src/app/shared/models/session-user';
import {takeWhile} from 'rxjs/operators';
import {Router} from '@angular/router';
import {UntypedFormControl, UntypedFormGroup} from '@angular/forms';
import {VisionService} from '../../services/vision.service';
import {RegisterUserComponent} from '../../pages/users/register-user/register-user.component';

declare var $: any;

@Component({
  selector: 'signed',
  templateUrl: './signed.component.html',
  styleUrls: ['./signed.component.css']
})
export class SignedComponent extends BaseComponent implements AfterViewInit {
  constructor(
    private router: Router,
    private appService: AppService,
    private visionService: VisionService) {
    super();
  }

  ngAfterViewInit() {
    $('.ui.inline.dropdown')
      .dropdown({
        clearable: true,
        placeholder: 'any'
      })
    ;
  }

  form = new UntypedFormGroup({
    visao: new UntypedFormControl(null)
  });

  @ViewChild('criaUsuario', {static: true})

  criaUsuario: RegisterUserComponent;
  visions = [];
  isDreReport = false;
  visionName: string;

  @Input('isDre') isDre: string;
  @Output('onVisionChange') onVisionChange = new EventEmitter<any>();

  user: SessionUser;

  ngOnInit() {
    this.user = this.appService.sessionUser;

    this.visionName = localStorage.getItem('visionName');

    if (!!this.user && this.isDre != null)
      this.listVisions();

    this.appService.sessionUserEvent.pipe(
      takeWhile(() => this.isAlive)
    ).subscribe(user => this.user = user);
  }

  signout() {
    this.appService.removeAccessToken();
    this.appService.removeUser();
    this.router.navigate(['/login']);
  }

  openConfig(userSession: SessionUser) {

    const user: any = {
      id: userSession?.id ?? this.user?.id
    };

    this.criaUsuario.open(user, true);

  }

  save($event) {
    this.criaUsuario.cancel();
    this.listVisions();
  }

  listVisions() {

    this.isDreReport = true;

    const params = {
      userId: this.user?.id
    };

    this.visionService
      .get(params)
      .pipe(
        takeWhile(() => this.isAlive)
      )
      .subscribe((resp) => {
        this.visions = resp?.data?.itens;

        if (this.visionName === null){
          localStorage.setItem('visionName', this.visions[0]?.name);
          localStorage.setItem('visionId', this.visions[0]?.id);
        }
      });
  }

  visionChange(id, visionName) {
    this.visionName = visionName;
    localStorage.setItem('visionName', visionName);
    localStorage.setItem('visionId', this.visions[0]?.id);

    if (!!id)
      this.onVisionChange.emit(id);
  }
}


