<signed></signed>

<div class="ui padded grid stackable">
  <div class="main-content m-t-10">
    <div ch-top-bar-breadcrumb></div>
    <div ch-grid-row>
      <div ch-grid-column tablet="16" computer="16">
        <div class="row m-b-10 m-t-20" ch-search-form color="teal" (on-clear)="clear($event)"
             (on-search)="search($event)">
          <form [formGroup]="form">
            <div class="two fields">
              <div class="field">
                <label>Nome</label>
                <input type="text" formControlName="name">
              </div>
              <div class="field">
                <label>E-mail</label>
                <input type="email" formControlName="email">
              </div>
            </div>
          </form>
          <div extra-buttons class="m-b-20">
            <button type="button" class="ui left labeled icon teal button" (click)="openConfig()">
              <i class="fal fa-plus icon"></i>
              Novo
            </button>
          </div>
        </div>
      </div>

      <div ch-grid-column tablet="16" computer="16">
        <grid-loading [grid]="grid"></grid-loading>
        <table class="ui striped small table" *ngIf="grid.itens.length > 0">
          <thead>
          <tr>
            <th class="center aligned checkbox no-sort"></th>
            <th ch-th-sort sort-field="FirstName,LastName" [current-sort-field]="grid.sortField"
                [current-sort-dir]="grid.sortType" (click)="grid.sort('FirstName,LastName')">
              Nome
            </th>
            <th ch-th-sort sort-field="Email" [current-sort-field]="grid.sortField" [current-sort-dir]="grid.sortType"
                (click)="grid.sort('Email')">
              E-mail
            </th>
            <th ch-th-sort sort-field="Profile" [current-sort-field]="grid.sortField" [current-sort-dir]="grid.sortType"
                (click)="grid.sort('Profile')">
              Perfil
            </th>
            <th ch-th-sort class="center aligned checkbox-column" sort-field="Active"
                [current-sort-field]="grid.sortField" [current-sort-dir]="grid.sortType" (click)="grid.sort('Active')">
              Status
            </th>
          </tr>
          </thead>
          <tbody>
          <tr *ngFor="let item of grid.itens; let i = index;">
            <td [attr.data-info]="'Opções'">
              <div ch-dp-btn color="tiny basic">
                <div class="header">Opções</div>
                <div class="item" (click)="openConfig(item)">
                <span class="ui tiny blue header">
                  <i class="fas fa-edit fa-icon fa-fw"></i>
                </span>
                  Editar
                </div>
                <div class="divider"></div>
                <div class="item" (click)="remove(item, i)">
                <span class="ui tiny red header">
                  <i class="fas fa-trash fa-icon fa-fw"></i>
                </span>
                  Deletar
                </div>
              </div>
            </td>
            <td data-info="Nome">{{item.fullName}}</td>
            <td data-info="E-mail">{{item.email}}</td>
            <td data-info="Perfil">{{item.profileDescription}}</td>
            <td  class="center aligned checkbox-column" data-info="Ativo?">
              <div [attr.data-info]="'Status'" data-label="'Active'"
                   [ngClass]="item.active ? 'ui teal horizontal label' : 'ui red horizontal label'">
                {{ item?.active ? 'Ativo' : 'Inativo' }}
              </div>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>

    <div grid-pagination [grid]="grid" [current-page]="grid?.pageIndex" [total-pages]="grid?.totalPages"></div>
  </div>
</div>

<register-user #criaUsuario (on-save)="save($event)"></register-user>
