import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import EnvelopeDataResult from '../models/envelope-data-result';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private urls = {
    v1: `${environment.urls.api.v1}/auth`
  }

  constructor(private http: HttpClient) { }

  signIn = (code: string): Observable<any> =>
    this.http.post(this.urls.v1, { code: code }).pipe(map(resp => resp))

  getSignInUrl = (): Observable<EnvelopeDataResult<string>> =>
    this.http.get<EnvelopeDataResult<string>>(this.urls.v1).pipe(map(resp => resp))

  logout = (identityToken: string): Observable<EnvelopeDataResult<string>> =>
    this.http.post<EnvelopeDataResult<string>>(
      `${this.urls.v1}/logout`,
      { identityToken: identityToken }
    ).pipe(map(resp => resp))
}
