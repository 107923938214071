import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/shared/services/auth.service';
import { Router, ActivatedRoute } from '@angular/router';
import { AppService } from 'src/app/shared/services/app.service';

@Component({
  selector: 'app-post-auth',
  templateUrl: './post-auth.component.html',
  styleUrls: ['./post-auth.component.css']
})
export class PostAuthComponent implements OnInit {

  constructor(
    private authService: AuthService,
    private router: Router,
    private actRoute: ActivatedRoute,
    private appService: AppService
  ) { }

  ngOnInit(): void {
    this.appService.spinner.show();

    const code = this.actRoute.snapshot.queryParams.code;

    if (!code) {
      return;
    }

    this.authService.signIn(code).subscribe(
      resp => {
        this.appService.storeAccessToken(resp.data.accessToken);
        this.appService.storeUser(resp.data.user);
        this.appService.spinner.hide();

        const returnUrl = this.appService.returnUrl;
        if (returnUrl) {
          this.appService.removeReturnUrl();
          this.router.navigateByUrl(returnUrl);
        } else {
          this.router.navigate(['/home']);
        }
      },
      error => {
        this.router.navigate(['/login']);
        console.error(error);
      }
    );
  }
}
