import {RouterModule, Routes} from '@angular/router';
import {ListUserComponent} from './pages/users/list-user/list-user.component';
import {AuthGuard} from './guards/auth.guard';
import {ListBatchComponent} from './pages/batches/list-batch/list-batch.component';
import {ListVisionsComponent} from './components/visions/list-visions/list-visions.component';
import {
  ListConfigReportComponent
} from '../dre/pages/relatorios-dres/config-report/list-config-report/list-config-report.component';
import {ForbiddenComponent} from './pages/forbidden/forbidden.component';
import {LoginComponent} from './pages/login/login.component';
import {PostAuthComponent} from './pages/auth/post-auth/post-auth.component';
import {PostLogoutComponent} from './pages/auth/post-logout/post-logout.component';
import {UsersReportComponent} from '../dre/pages/relatorios-dres/users-report/users-report.component';
import {NgModule} from '@angular/core';


const routes: Routes = [


    {
      path: 'config',
      children: [
        {path: 'users', component: ListUserComponent, canActivate: [AuthGuard]},
        {path: 'batches', component: ListBatchComponent, canActivate: [AuthGuard]},
        {
          path: 'visions', children: [
            {path: '', component: ListVisionsComponent},
            {path: 'config-report/:id/:nome', component: ListConfigReportComponent},
          ]
        },
      ]
    },
    {path: 'forbidden', component: ForbiddenComponent, canActivate: [AuthGuard]},
    {path: 'login', component: LoginComponent},
    {path: 'post_auth', component: PostAuthComponent},
    {path: 'post_logout', component: PostLogoutComponent},
    {
      path: 'reports',
      children: [
        {path: 'users', component: UsersReportComponent, canActivate: [AuthGuard]},
      ]
    }
  ]
;

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class SharedRoutingModule {
}
