import {
  Component,
  EventEmitter, Input,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import {BaseComponent} from "src/app/shared/components/base/base.component";
import {AppService} from "src/app/shared/services/app.service";
import { DialogModalComponent } from "src/app/shared/dialog/dialog-modal.component";

@Component({
  selector: "accounting-account-list",
  templateUrl: "./accounting-account-list.component.html",
  styleUrls: ["./accounting-account-list.component.css"],
})
export class AccountingCcountList
  extends BaseComponent
  implements OnInit {
  @ViewChild("dialog", { static: true }) dialog: DialogModalComponent;
  @Output("on-save") onSave = new EventEmitter<any>();
  @Output("on-close") onClose = new EventEmitter<any>();


  titulo: string;
  secao : any;

  constructor(
    private appService: AppService
  ) {
    super();
  }

  ngOnInit(): void {

  }

  open(secao) {
    this.titulo = 'Contas Contábeis';
    this.secao = secao;

    this.dialog.open();
  }

  cancel() {
    this.dialog.close();
  }

}
