import { Component, OnInit } from '@angular/core';
import {BaseComponent} from '../../../../shared/components/base/base.component';
import {PagedListService} from 'ngx-paged-list';
import {environment} from '../../../../../environments/environment';
import {AppService} from '../../../../shared/services/app.service';
import {TopBarBreadcrumbService} from 'ngx-chameleon';
import {FormControl, FormGroup} from '@angular/forms';
import {UserService} from '../../../../shared/services/user.service';
import {allProfiles} from '../../../enums/profile';
import {ExcelService} from "../../../../shared/services/excel.service";



@Component({
  selector: 'app-users-report',
  templateUrl: './users-report.component.html',
  styleUrls: ['./users-report.component.css']
})
export class UsersReportComponent extends BaseComponent implements OnInit {

  constructor(
    private appService: AppService,
    private topBarBreadcrumbService: TopBarBreadcrumbService,
    private userService: UserService,
    private excelService: ExcelService
  ) {
    super();
    this.grid = new PagedListService({
      isAlive: this.isAlive,
      onLoadStarts: () => this.appService.spinner.show(),
      onLoadFinished: () => this.appService.spinner.hide(),
      pageSize: 20,
      sortField: 'Id',
      sortType: 'desc',
      url: `${environment.urls.api.v1}/users-report`,
    });
  }

  grid: PagedListService;

  form = new FormGroup({
    id: new FormControl<string | null>(''),
    firstName: new FormControl<string | null>(''),
    lastName: new FormControl<string | null>(''),
    email: new FormControl<string | null>(''),
    solutionsPortalUserName: new FormControl<string | null>(''),
    profile: new FormControl<string | null>('')
  });

  profiles = allProfiles;

  ngOnInit(): void {

    this.topBarBreadcrumbService.emmiter.next({
      icon: 'fas fa-cogs fa-icon',
      title: 'Relatório de Usuários',
      path: ['Relatórios', 'Usuários']
    });

    this.grid.load();
  }

  clear($event) {
    this.form = new FormGroup({
      id: new FormControl<string | null>(''),
      firstName: new FormControl<string | null>(''),
      lastName: new FormControl<string | null>(''),
      email: new FormControl<string | null>(''),
      solutionsPortalUserName: new FormControl<string | null>(''),
      profile: new FormControl<string | null>('')
    });
    this.grid.load({}, false);
  }

  search($event) {
    this.grid.load({ ...this.form.value }, true);
  }

  exportToExcel(){
    this.userService.getExcel(this.form.value)
      .subscribe(resp => this.excelService.saveBase64AsFile(resp.buffer, resp.fileName, resp.contentType));
  }

}
