import {Component, OnInit, ViewChild} from '@angular/core';
import {AlertService, TopBarBreadcrumbService} from 'ngx-chameleon';
import {PagedListService} from 'ngx-paged-list';
import {BaseComponent} from 'src/app/shared/components/base/base.component';
import {finalize, takeWhile} from 'rxjs/operators';
import {AppService} from 'src/app/shared/services/app.service';
import {UntypedFormControl, UntypedFormGroup} from '@angular/forms';
import {tipoParametrosEnum} from '../../../../enums/tipoParametrosEnum';
import {ActivatedRoute} from '@angular/router';
import {ConfigReportComponent} from '../config-report.component';
import {EstruturaConfigRelatoriosService} from '../../../../services/estrutura-config-relatorios.service';
import { AccountingCcountList } from '../accounting-account-list/accounting-account-list.component';
import { AccountingCalcView } from '../acconting-calc-view/acconting-calc-view.component';


@Component({
  selector: 'app-list-config-report',
  templateUrl: './list-config-report.component.html',
  styleUrls: ['./list-config-report.component.css'],
})
export class ListConfigReportComponent extends BaseComponent implements OnInit {
  @ViewChild('configReportSynthetic', {static: true})
  configReport: ConfigReportComponent;
  @ViewChild('accountingCcountList', {static: true})
  accountingCcountList: AccountingCcountList;
  @ViewChild('accountingCalcView', {static: true})
  accountingCalcView: AccountingCalcView;
  visions = [];

  grid: PagedListService;
  parametros = tipoParametrosEnum;
  user: any;

  constructor(
    private topBarBreadcrumbService: TopBarBreadcrumbService,
    private appService: AppService,
    private estruturaService: EstruturaConfigRelatoriosService,
    private actRoute: ActivatedRoute,
    private alertService: AlertService) {
    super();
  }

  form = new UntypedFormGroup({
    vision: new UntypedFormControl(null)
  });

  cols: any;
  secoes: any;
  visionId: string;
  visionName: string;
  params = {};

  ngOnInit(): void {

    this.visionId = this.actRoute.snapshot.params.id;

    this.visionName = this.actRoute.snapshot.params.nome;

    this.topBarBreadcrumbService.emmiter.next({
      icon: 'fas fa-cogs fa-icon',
      title: 'Configuração Estrutura Conta Contábil',
      path: ['Configurações', 'Visão Gerencial', 'Estrutura Contábil', this.visionName],
    });


    this.params = {visionId: this.visionId};

    this.getWithFilter(this.params ?? null);

    this.cols = [
      {field: 'id', header: ''},
      {field: 'nome', header: 'Nome'},
      {field: 'negrito', header: 'Negrito'},
      {field: 'calculo', header: 'Cálculo'},
      {field: 'porcentagem', header: 'Porcentagem'},
      {field: 'corFonteDescricao', header: 'Cor da Fonte'},
      {field: 'recuoDireita', header: 'Recuo à Direita'},
      {field: 'maiuscula', header: 'Maiúscula'},
      {field: 'tipoFonteDescricao', header: 'Tamanho da Fonte'},
      {field: 'possuiDivisor', header: 'Divisor'},
      {field: 'ordenacao', header: 'Ordem'}

    ];

  }

  atualizaOrdenacao(event) {

    const obj = {
      tipoParametro: this.parametros.Ordenacao,
      indiceOrdenacaoInicial: event.dragIndex,
      indiceOrdenacaoFinal: event.dropIndex
    };

    this.appService.spinner.show();

    this.estruturaService
      .editParamsDinamics(obj, this.visionId)
      .pipe(
        takeWhile(() => this.isAlive),
        finalize(() => this.appService.spinner.hide())
      )
      .subscribe(() => {
        this.appService.toastr.success(
          'Sessão atualizada com sucesso!',
          'Sucesso!'
        );
        this.getWithFilter(this.params ?? null);
      });
  }

  save($event) {
    location.reload();
  }

  openConfig(id?: string) {
    this.configReport.form.reset();
    this.configReport.open(this.secoes, this.visionId, id);
  }

  getWithFilter(filter?: any) {
    this.appService.spinner.show();

    this.estruturaService
      .getWithFilter(filter)
      .pipe(
        takeWhile(() => this.isAlive),
        finalize(() => this.appService.spinner.hide())
      )
      .subscribe((result) => {
        this.secoes = result?.data?.itens;
      });
  }

  verContas(id: any){
    var secao = this.secoes.find(x => x.id == id); 
    if (!!secao)
      this.accountingCcountList.open(secao);
    else
      this.appService.toastr.error("A seção não foi encontrada")
  }

  verCalculo(id: any) {
    var secao = this.secoes.find(x => x.id == id);
    if (!!secao)
      this.accountingCalcView.open(secao);
    else
      this.appService.toastr.error("A seção não foi encontrada")
  }

  temContas(id: any){
    var secao = this.secoes.find(x => x.id == id);
    return secao.contasContabeisRelatoriosSinteticos?.length > 0
  }

  remove(id: any, index: number) {

    this.alertService.confirm({
      cancelText: 'Não',
      confirmText: 'Sim',
      headerTitle: `Linha ${index + 1}`,
      confirmBtnColor: 'red',
      cancelBtnColor: 'gray',
      msg: 'Tem certeza que deseja excluir a linha?',
      confirmFn: () => {
        this.appService.spinner.show();
        this.estruturaService
          .remove(id, this.visionId)
          .pipe(
            takeWhile(() => this.isAlive),
            finalize(() => this.appService.spinner.hide())
          )
          .subscribe((res) => {

            if (!res.data.success) {
              this.appService.toastr.warning(
                `${res.data.message}`,
                'Atenção!'
              );
            } else {
              this.appService.toastr.success(
                `${res.data.message}`,
                'Sucesso!'
              );
            }

            location.reload();

          });
      },
    });

  }

}
