<ch-dialog  #dialog width="60%" [title]="'<h2>{{title}}</h2>'" [title-is-html]="true"
            title="<h2> <i class='fas fa-list fa-icon'></i>&nbsp;{{title}}</h2>"
  [close-on-click]="false">


  <form [formGroup]="formBatch" class="m-t-30 ui form">
    <div class="container">
    <div class="field required">
      <label>Lote</label>

      <input type="text" formControlName="nome">
      <ch-pointing-label *ngIf="submitted && formBatch.get('nome')?.errors?.required">
        Lote não pode ser vazio
      </ch-pointing-label>
    </div>

    <div class="field required">
      <label>Ano</label>
      <input type="text" formControlName="ano">
      <ch-pointing-label *ngIf="submitted && formBatch.get('ano')?.errors?.required">
        Ano não pode ser vazio
      </ch-pointing-label>
    </div>

    <div class="ui clearing basic segment m-t-30 m-b-10">

      <button type="button" class="ui left labeled icon gray button m-r-10" (click)="cancel()">
        <i class="fal cancel icon"></i>
        Cancelar
      </button>

      <button type="button" class="ui left labeled icon teal button" (click)="save()">
        <i class="fal fa-save icon"></i>
        Salvar
      </button>
    </div>

    </div>

  </form>

</ch-dialog>
