<signed></signed>

<div class="ui padded grid stackable">
  <div class="main-content m-t-10">
    <div ch-top-bar-breadcrumb></div>

    <div ch-grid-row>
      <div ch-grid-column tablet="16" computer="16">
        <div class="row m-b-10 m-t-20" ch-search-form color='teal' (on-clear)="clear($event)" (on-search)="search($event)">
          <div extra-buttons class="m-b-10">
            <button type="button" class="ui left labeled icon teal button" (click)="openConfig()">
              <i class="fal fa-plus icon"></i>
              Novo
            </button>

          </div>
          <form [formGroup]="form">
            <div class="four fields">
              <div class="field">
                <label>Visão Gerencial</label>
                <input type="text" formControlName="name"/>
              </div>

              <div class="field">
                <label>Status</label>
                <ng-select
                  [items]="status"
                  bindValue="id" bindLabel="name"
                  formControlName="active"
                  appendTo="body"
                >
                </ng-select>
              </div>

            </div>
          </form>
        </div>

        <div ch-grid-column tablet="16" computer="16">
          <grid-loading [grid]="grid"></grid-loading>

        <table class="ui striped small table" *ngIf="grid.itens.length > 0">
          <thead>
          <tr>
            <th>

            </th>
            <th>
              Nome
            </th>
            <th>
              Criado em
            </th>
            <th>
              Criado por
            </th>
            <th>
              Atualizado em
            </th>

            <th>
              Atualizado por
            </th>

            <th class="center aligned checkbox-column">
              Status
            </th>
          </tr>
          </thead>

          <tbody>
          <tr *ngFor="let item of grid?.itens; let i = index;">

            <td [attr.data-info]="'Opções'">
              <div ch-dp-btn color="tiny basic">
                <div class="header">Opções</div>

                <div class="item" (click)="openConfig(item.id)">
                <span class="ui tiny blue header">
                  <i class="fas fa-edit fa-icon fa-fw"></i>
                </span>
                  Editar
                </div>
                <div *ngIf="item?.active" class="item" [routerLink]="['/config/visions/config-report', item.id, item.name]">
                <span class="ui tiny green header">
                  <i class="fas fa-eye fa-icon fa-fw"></i>
                </span>
                  Estrutura Contábil
                </div>
                <div class="item" (click)="updateStatus(item.id, item)">
                <span class="ui tiny orange header">
                  <i [ngClass]="item.active ? 'fas fa-thumbs-down' : 'fas fa-thumbs-up' "></i>
                </span>
                  {{item.active ? 'Inativar' : 'Ativar'}}
                </div>
                <div class="divider"></div>
                <div class="item" (click)="delete(item.id, item.name)">
                <span class="ui tiny red header">
                  <i class="fas fa-trash fa-icon fa-fw"></i>
                </span>
                  Deletar
                </div>

              </div>
            </td>

            <td>
              <div data-label="'Nome'">
                {{ item?.name }}
              </div>
            </td>

            <td>
              <div data-label="'CriadoEm'">
                {{ item?.createdIn | date: 'dd/MM/yyyy HH:mm'}}
              </div>
            </td>

            <td>
              <div data-label="'CriadoPor'">
                {{ item?.createdByName}}
              </div>
            </td>
            <td>
              <div data-label="'AtualizadoEm'" [ngStyle]="{'margin-left': item?.updatedIn == null ? '15%' : ''}">
                {{ item?.updatedIn ? (item?.updatedIn | date: 'dd/MM/yyyy HH:mm') : '-' }}
              </div>
            </td>
            <td>
              <div data-label="'AtualizadoPor'" [ngStyle]="{'margin-left': item?.updatedByName == '' ? '15%' : ''}">
                {{ item?.updatedByName != '' ? item?.updatedByName : '-'}}
              </div>

            </td>

            <td  class="center aligned checkbox-column">
              <div [attr.data-info]="'Status'" data-label="'Active'"
                   [ngClass]="item.active ? 'ui teal horizontal label' : 'ui red horizontal label'">
                {{ item?.active ? 'Ativo' : 'Inativo' }}
              </div>
            </td>

          </tr>
          </tbody>
        </table>

        </div>
    </div>

    <div grid-pagination [grid]="grid" [current-page]="grid?.pageIndex" [total-pages]="grid?.totalPages"></div>

    <create-vision #criacaoVisao (on-save)="save($event)"></create-vision>

  </div>
</div>
</div>
