<signed></signed>

<div class="ui padded grid stackable">
  <div class="main-content m-t-10" >
    <div ch-top-bar-breadcrumb style="margin-bottom: 5px"></div>
    <div ch-grid-row>

      <div ch-grid-column tablet="16" computer="16">
        <div class="row m-b-10 m-t-20" ch-search-form color='teal' (on-clear)="clear($event)" (on-search)="search($event)">
          <form [formGroup]="form">
            <div class="two fields">
              <div class="field">
                <label>Nome</label>
                <input type="text" formControlName="nome">
              </div>
            </div>
          </form>
          <div extra-buttons class="m-b-10">
            <button type="button" class="ui left labeled icon teal button" (click)="create()">
              <i class="fal fa-plus icon"></i>
              Novo
            </button>

          </div>
        </div>
        <grid-loading [grid]="grid"></grid-loading>

        <table class="ui striped small table" *ngIf="grid?.itens?.length > 0">
          <thead>
          <tr>
            <th>

            </th>

            <th (click)="grid.sort('Nome')" ch-th-sort sort-field="Nome" [current-sort-field]="grid.sortField"
                [current-sort-dir]="grid.sortType">
              Nome
            </th>

            <th (click)="grid.sort('Ano')" ch-th-sort sort-field="Ano" [current-sort-field]="grid.sortField"
                [current-sort-dir]="grid.sortType">
              Ano
            </th>

            <th (click)="grid.sort('CriadoEm')" ch-th-sort>
              Criado Em
            </th>

            <th (click)="grid.sort('CriadoPor.FullName')" ch-th-sort sort-field="CriadoPor.FullName"
                [current-sort-field]="grid.sortField" [current-sort-dir]="grid.sortType">
              CriadoPor
            </th>
          </tr>
          </thead>

          <tbody>
          <tr *ngFor="let item of grid?.itens; let i = index;">
            <td [attr.data-info]="'Opções'">
              <div ch-dp-btn color="tiny basic">
                <div class="header">Opções</div>


                <div class="item" (click)="edit(item)">
                <span class="ui tiny blue header">
                  <i class="fas fa-edit fa-icon fa-fw"></i>
                </span>
                  Editar
                </div>

                <div class="divider"></div>
                <div class="item" (click)="remove(item.id, item.nome)">
                <span class="ui small red header">
                  <i class="fas fa-trash fa-icon fa-fw"></i>
                </span>
                  Deletar
                </div>

              </div>
            </td>

            <td [attr.data-info]="'Nome'">
              {{ item.nome }}
            </td>

            <td [attr.data-info]="'Ano'">
              {{ item.ano }}
            </td>

            <td [attr.data-info]="'CriadoEm'">
              {{ item.criadoEm | date : 'dd/MM/yyyy'}}
            </td>

            <td [attr.data-info]="'CriadoPor'">
              {{item.criadoPor?.fullName}}
            </td>
          </tr>
          </tbody>
        </table>
      </div>
      <div grid-pagination [grid]="grid" [current-page]="grid?.pageIndex" [total-pages]="grid?.totalPages">
      </div>
    </div>
  </div>
</div>
<batch-create (on-save)="onSaveBatch()" #batchCreate>
</batch-create>


