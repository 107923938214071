import { Component, OnInit } from '@angular/core';
import { AppService } from 'src/app/shared/services/app.service';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { AuthService } from 'src/app/shared/services/auth.service';
import { takeWhile, finalize } from 'rxjs/operators';
import { BaseComponent } from '../../components/base/base.component';
import { ActivatedRoute, Router } from '@angular/router';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent extends BaseComponent implements OnInit {
  solutionsPortalUrl: string;

  returnUrl = '/';
  submited = false;

  form = new UntypedFormGroup({
    email: new UntypedFormControl('', Validators.compose([
      Validators.required,
      Validators.email
    ])),
    password: new UntypedFormControl('', Validators.required)
  });


  constructor(
    private router: Router,
    private appService: AppService,
    private route: ActivatedRoute,
    private authService: AuthService
  ) {
    super();
  }

  ngOnInit() {
    // get return url from route parameters or default to '/'
    this.returnUrl = this.route.snapshot.queryParams.returnUrl || '/';
    this.appService.storeReturnUrl(this.returnUrl);

    // $(this.langDropdwon.nativeElement).dropdown();
    this.authService.getSignInUrl()
      .pipe(
        takeWhile(() => this.isAlive),
        finalize(() => this.appService.spinner.hide()),
      )
      .subscribe(res => {
        this.solutionsPortalUrl = res.data;
      });
  }
}
