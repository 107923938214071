import { Injectable } from '@angular/core';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import {UtilsService} from './utils.service';



const EXCEL_TYPE_XLSX = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';

@Injectable({
  providedIn: 'root'
})
export class ExcelService {

  constructor(
    private utilsService: UtilsService
  ) { }

  public exportAsExcelFile(json: any[], excelFileName: string,  fnStart?: Function, fnStop?: Function): void {
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json);
    const workbook: XLSX.WorkBook = { Sheets: { 'Planilha': worksheet }, SheetNames: ['Planilha'] };
    const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    if(fnStart) fnStart();
    this.saveAsExcelFile(excelBuffer, excelFileName);
    if(fnStop) fnStop();
  }

  public saveAsExcelFile(buffer: any, fileName: string): void {
    const data: Blob = new Blob([buffer], {
      type: EXCEL_TYPE_XLSX
    });
    FileSaver.saveAs(data, fileName + '_' + new Date().getTime() + EXCEL_EXTENSION);
  }

  public saveBase64AsFile(buffer: any, fileName: string, contentType: string): void {
    let blob = this.utilsService.b64toBlob(buffer, contentType);
    FileSaver.saveAs(blob, fileName);
  }
}
