import {CommonModule, DatePipe} from '@angular/common';
import { NgModel } from '@angular/forms';
import { DEFAULT_CURRENCY_CODE, LOCALE_ID, NgModule } from '@angular/core';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { ToastrModule } from 'ngx-toastr';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AppHttpInterceptor } from './app-http.interceptor';
import localePt from '@angular/common/locales/pt';
import { registerLocaleData } from '@angular/common';
import {SharedModule} from './shared/shared.module';
import {NgxSpinnerModule} from 'ngx-spinner';
import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';


registerLocaleData(localePt, 'pt');
@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    SharedModule.forRoot(),
    ToastrModule.forRoot({
      enableHtml: true,
      progressBar: true,
      timeOut: 2500,
      positionClass: 'toast-top-center',
      maxOpened: 1,
      progressAnimation: 'decreasing',
      preventDuplicates: true,
    }),
    AppRoutingModule,
    NgxSpinnerModule,
    CommonModule,
    BrowserModule,
    BrowserAnimationsModule

  ],
  providers: [
    NgModel,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AppHttpInterceptor,
      multi: true,
    },
    {
      provide: LOCALE_ID,
      useValue: 'pt',
    },
    {
      provide: DEFAULT_CURRENCY_CODE,
      useValue: 'BRL',
    },
    DatePipe
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
