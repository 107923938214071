import {
  Component,
  EventEmitter, Input,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import {BaseComponent} from "src/app/shared/components/base/base.component";
import {AppService} from "src/app/shared/services/app.service";
import { DialogModalComponent } from "src/app/shared/dialog/dialog-modal.component";

@Component({
  selector: "accounting-calc-view",
  templateUrl: "./acconting-calc-view.component.html",
  styleUrls: ["./acconting-calc-view.component.css"],
})
export class AccountingCalcView
  extends BaseComponent
  implements OnInit {
  @ViewChild("dialog", { static: true }) dialog: DialogModalComponent;
  @Output("on-save") onSave = new EventEmitter<any>();
  @Output("on-close") onClose = new EventEmitter<any>();


  titulo: string;
  secao : any;

  constructor(
    private appService: AppService
  ) {
    super();
  }

  ngOnInit(): void {

  }

  open(secao) {
    this.titulo = 'Cálculo';
    this.secao = secao;

    this.dialog.open();
  }

  cancel() {
    this.dialog.close();
  }

}
