import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import {Excel, ExcelData} from '../models/Excel';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  private urls = {
    v1: `${environment.urls.api.v1}/users`,
    usersReport: `${environment.urls.api.v1}/users-report`
  }

  constructor(private http: HttpClient) { }


  create = (data: any):Observable<any> => this.http.post<any>(`${this.urls.v1}`, data).pipe(map(resp => resp));

  remove = (id: string):Observable<any> => this.http.delete<any>(`${this.urls.v1}/${id}`).pipe(map(resp => resp));

  edit = (id: string, data: any):Observable<any> => this.http.put<any>(`${this.urls.v1}/${id}`, data).pipe(map(resp => resp));

  get = (filter?: any):Observable<any> => this.http.get<any>(`${this.urls.v1}`, {params: filter}).pipe(map(resp => resp));

  getExcel = (filter?: any):Observable<ExcelData> => {
    return this.http.get<Excel>(
      `${this.urls.usersReport}/gerar-excel`,
      {params: filter}).pipe(map(resp => resp.data));
  }


}
