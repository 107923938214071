<signed></signed>

<div class="ui padded grid stackable">
  <div class="main-content m-t-10">
    <div ch-top-bar-breadcrumb></div>

    <div ch-grid-row>
      <div ch-grid-column tablet="16" computer="16">
        <div class="row m-b-10 m-t-20" ch-search-form color="teal" (on-clear)="clear($event)" (on-search)="search($event)">
          <div extra-buttons class="m-b-10">
            <div class="ui left labeled icon teal button" (click)="exportToExcel()">
              <i class="file excel icon"></i>Exportar
            </div>
          </div>
          <form [formGroup]="form">
            <div class="four fields">
              <div class="field">
                <label>Nome</label>
                <input type="text" formControlName="firstName">
              </div>
              <div class="field">
                <label>Sobrenome</label>
                <input type="text" formControlName="lastName">
              </div>
              <div class="field">
                <label>E-mail</label>
                <input type="email" formControlName="email">
              </div>
              <div class="field">
                <label>Perfil</label>
                <ng-select
                  appendTo="body"
                  [items]="profiles"
                  bindValue="value"
                  bindLabel="description"
                  formControlName="profile">
                </ng-select>
              </div>
            </div>
          </form>
        </div>
        <div ch-grid-column tablet="16" computer="16">
          <grid-loading [grid]="grid"></grid-loading>
          <table class="ui striped sortable small table" *ngIf="grid.itens.length > 0">
            <thead>
            <tr>
              <th ch-th-sort sort-field="Id" [current-sort-field]="grid.sortField"
                  [current-sort-dir]="grid.sortType" (click)="grid.sort('Id')">
                Id
              </th>
              <th ch-th-sort sort-field="FirstName" [current-sort-field]="grid.sortField"
                  [current-sort-dir]="grid.sortType" (click)="grid.sort('FirstName')">
                Nome
              </th>
              <th ch-th-sort sort-field="Email" [current-sort-field]="grid.sortField" [current-sort-dir]="grid.sortType"
                  (click)="grid.sort('Email')">
                E-mail
              </th>
              <th ch-th-sort sort-field="Profile" [current-sort-field]="grid.sortField" [current-sort-dir]="grid.sortType"
                  (click)="grid.sort('Profile')">
                Perfil
              </th>
              <th ch-th-sort sort-field="SolutionsPortalUserName" [current-sort-field]="grid.sortField" [current-sort-dir]="grid.sortType"
                  (click)="grid.sort('SolutionsPortalUserName')">
                Usuário Portal Soluções
              </th>
              <th ch-th-sort class="center aligned checkbox-column" sort-field="Active"
                  [current-sort-field]="grid.sortField" [current-sort-dir]="grid.sortType" (click)="grid.sort('Active')">
                Status
              </th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let item of grid.itens; let i = index;">
              <td data-info="Id">{{item.id}}</td>
              <td data-info="Nome">{{item.fullName}}</td>
              <td data-info="E-mail">{{item.email}}</td>
              <td data-info="Perfil">{{item.profileDescription}}</td>
              <td data-info="SolutionsPortalUserName">{{item.solutionsPortalUserName}}</td>
              <td  class="center aligned checkbox-column" data-info="Ativo?">
                <div [attr.data-info]="'Status'" data-label="'Active'"
                     [ngClass]="item.active ? 'ui teal horizontal label' : 'ui red horizontal label'">
                  {{ item?.active ? 'Ativo' : 'Inativo' }}
                </div>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div grid-pagination [grid]="grid" [current-page]="grid?.pageIndex" [total-pages]="grid?.totalPages"></div>
    </div>
  </div>
</div>

