import {Component, OnInit, ViewChild} from '@angular/core';
import { BaseComponent } from 'src/app/shared/components/base/base.component';
import { TopBarBreadcrumbService, AlertService } from 'ngx-chameleon';
import { AppService } from 'src/app/shared/services/app.service';
import { UserService } from 'src/app/shared/services/user.service';
import { PagedListService } from 'ngx-paged-list';
import { environment } from 'src/environments/environment';
import { takeWhile, finalize } from 'rxjs/operators';
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import {RegisterUserComponent} from "../register-user/register-user.component";
import {User} from "../../../models/user";

@Component({
  selector: 'app-list-user',
  templateUrl: './list-user.component.html',
  styleUrls: ['./list-user.component.css']
})
export class ListUserComponent extends BaseComponent implements OnInit {

  constructor(
    private topBarBreadcrumbService: TopBarBreadcrumbService,
    private alertService: AlertService,
    private appService: AppService,
    private userService: UserService
  ) {
    super();

    this.grid = new PagedListService({
      isAlive: this.isAlive,
      onLoadStarts: () => this.appService.spinner.show(),
      onLoadFinished: () => this.appService.spinner.hide(),
      pageSize: 20,
      sortField: 'FirstName,LastName',
      sortType: 'asc',
      url: `${environment.urls.api.v1}/users`,
    });
  }

  grid: PagedListService;
  @ViewChild("criaUsuario", {static: true})

  criaUsuario: RegisterUserComponent;
  form = new UntypedFormGroup({
    name: new UntypedFormControl(null),
    email: new UntypedFormControl(null)
  });

  ngOnInit() {

    this.topBarBreadcrumbService.emmiter.next({
      icon: 'fas fa-cogs fa-icon',
      title: 'Usuários',
      path: ['Configurações', 'Usuários']
    });

    this.grid.load();
  }

  search($event) {
    this.grid.load({ ...this.form.value }, true);
  }

  save($event) {
    this.grid.load();
  }

  clear($event) {
    this.appService.forms.clear(this.form);
    this.grid.load({}, false);
  }

  remove(data, i) {
    const msg = `Deseja remover o usuário ${data.fullName}?`;
    const successMsg = `Usuário ${data.fullName} removido com sucesso`;
    this.alertService.confirm({
      msg,
      confirmFn: () => {
        this.appService.spinner.show();
        this.userService.remove(data.id).pipe(
          takeWhile(() => this.isAlive),
          finalize(() => this.appService.spinner.hide())
        ).subscribe(resp => {
          this.grid.itens.splice(i, 1)
          this.appService.toastr.success(successMsg, 'Sucesso');
        });
      }
    });
  }

  onSave(){
    this.grid.load()
  }
  openConfig(user?: User) {
    this.criaUsuario.open(user);
  }
}

