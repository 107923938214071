enum  tipoParametrosEnum{
  CorFonte = 'CorFonte',
  RecuoDireita = 'RecuoDireita',
  TipoFonte = 'TipoFonte',
  Maiuscula = 'Maiuscula',
  Ordenacao = 'Ordenacao'
}
const tiposParametros = [
  {value: 'CorFonte', text : 'CorFonte'},
  {value: 'RecuoDireita', text: 'RecuoDireita'},
  {value: 'TipoFonte', text: 'TipoFonte'},
  {value: 'Maiuscula', text: 'Maiuscula'}
];

export { tipoParametrosEnum, tiposParametros };

