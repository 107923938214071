<ch-dialog-modal
  #dialog
  width="60%"
  title="<h2> <i class='fal fa-list icon'></i>&nbsp;{{ titulo }}</h2>"
  [title-is-html]="true"
  [close-on-click]="false"
>
  <div class="container container-accouting">
      <p class="content--font-size">{{secao?.expressao}}</p>
  </div>
</ch-dialog-modal>
