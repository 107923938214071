import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class BatchService {

  private urls = {
    v1: `${environment.urls.api.v1}/batches`
  }

  constructor(private http: HttpClient){

  }

  create = (data: any):Observable<any> => this.http.post<any>(`${this.urls.v1}`, data).pipe(map(resp => resp));

  edit = (data: any):Observable<any> => this.http.put<any>(`${this.urls.v1}/${data.id}`, data).pipe(map(resp => resp));

  remove = (id: string):Observable<any> => this.http.delete<any>(`${this.urls.v1}/${id}`).pipe(map(resp => resp));
}
