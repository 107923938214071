<div class="ui menu">
  <img [routerLink]="['/dre/online']" class="logo" src="../../../../assets/images/ch-logo.png"/>

  <div class="item"></div>

  <a class="item"  id="itens-menu"
     routerLinkActive="active"
     [routerLink]="['/dre']">
    DRE
  </a>

  <div class="item divider" style="color: white">
    |
  </div>
  <a class="item"
  routerLinkActive="active"
  [routerLink]="['../../projecao-demanda/']">
 Projeção de Demanda
</a>
  <div class="item divider" style="color: white">
    |
  </div>

  <div class="item">
    <div class="ui simple dropdown icon">
      Relatórios <i class="fal fa-chevron-down m-l-5" style="font-size: 10px"></i>
      <div class="ui vertical menu">
        <div class="item" [routerLink]="['/reports/users']" routerLinkActive="active">Usuários</div>
      </div>
    </div>
  </div>
  <div class="item divider" style="color: white">
    |
  </div>
  <div class="item">
    <div class="ui simple dropdown icon">
       Configurações <i class="fal fa-chevron-down m-l-5" style="font-size: 10px"></i>
      <div class="ui vertical menu">
        <div class="item" [routerLink]="['/config/visions']" routerLinkActive="active">Visões Gerenciais</div>
        <div class="item" [routerLink]="['/config/batches']" routerLinkActive="active">Lotes</div>
        <div class="item" [routerLink]="['/config/users']" routerLinkActive="active">Usuários</div>
      </div>
    </div>
  </div>

  <div class="item right">

    <div class="item">
      <div class="ui inline dropdown" *ngIf="isDre">
        <div class="text">
         {{ visionName ?? visions[0]?.name}}
        </div>
        <i class="fal fa-chevron-down m-l-5" style="font-size: 10px;"></i>
        <div class="menu">
          <div *ngFor="let item of visions; let i = index;">
            <div *ngIf="item?.name === visionName" class=" active item" id="{{item?.name}}"
                 (click)="visionChange(item.id, item.name)">
              {{item?.name}}
            </div>
            <div *ngIf="item?.name !== visionName" class="item" id="{{item?.name}}"
                 (click)="visionChange(item.id, item.name)">
              {{item?.name}}
            </div>
          </div>
        </div>
      </div>
    </div>
    <header>
      <ch-top-bar-menu>
        <div class="ch-header-nav-button ch-dropdown ch-user" href="#">
          <button class="ch-avatar ch-dropdown-toggle" type="button">
            <img class="ui avatar image" src="../../../../assets/images/avatar.png"/>
          </button>
          <div class="ch-dropdown-content bottom right" tabindex="-1">
            <div class="ch-user-container">
              <div class="ch-user-header" *ngIf="user">
                <img class="avatar" src="../../../../assets/images/avatar.png">
                <div class="info">
                  <strong class="name">{{user.fullName}}</strong>
                  <div class="email">{{user.email}}</div>
                </div>
              </div>
              <div class="ch-user-action">
                <a noAction (click)="openConfig(user)">
                  <i class="fal fa-user fa-icon"></i>
                  <span class="label">
                      Perfil
                    </span>
                </a>
              </div>
              <div class="ch-user-action">
                <a noAction (click)="signout()">
                  <i class="fal fa-sign-out fa-icon"></i>
                  <span class="label">
                      Sair
                    </span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </ch-top-bar-menu>
    </header>
  </div>
</div>

<register-user #criaUsuario (on-save)="save($event)"></register-user>
