import { Injectable, EventEmitter } from '@angular/core';
import { environment } from 'src/environments/environment';
import { SessionUser } from '../models/session-user';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from 'ngx-spinner';
import { UntypedFormArray, UntypedFormGroup, AbstractControl } from '@angular/forms';

@Injectable({
  providedIn: 'root'
})
export class AppService {

  constructor(
    private toastrService: ToastrService,
    private spinnerService: NgxSpinnerService) {
    this.forms = new CustomFormsService();
    this.toastr = new CustomToastrService(toastrService);
    this.spinner = new CustomSpinnerService(spinnerService);
    this.dateUtils = new DateUtils();
  }

  forms: CustomFormsService;
  toastr: CustomToastrService;
  spinner: CustomSpinnerService;
  dateUtils: DateUtils;


  private _sessionUser: SessionUser;

  public get sessionUser(): SessionUser {
    if (!this._sessionUser) {
      let jsonUserData = localStorage.getItem(environment.localStore.user);
      this._sessionUser = jsonUserData ? JSON.parse(jsonUserData) : null;
    }
    return this._sessionUser;
  }

  sessionUserEvent = new EventEmitter<SessionUser>();

  storeUser(sessionUser: SessionUser) {
    this._sessionUser = sessionUser;
    this.sessionUserEvent.next(sessionUser);
    localStorage.setItem(environment.localStore.user, JSON.stringify(sessionUser));
  }

  removeUser() {
    localStorage.removeItem(environment.localStore.user);
    this._sessionUser = null;
    this.sessionUserEvent.next(null);
  }

  get accessToken(): string {
    return localStorage.getItem(environment.localStore.token);
  }

  storeAccessToken = (token: string) => localStorage.setItem(environment.localStore.token, token);

  removeAccessToken = () => localStorage.removeItem(environment.localStore.token);

  get returnUrl(): string {
    return localStorage.getItem(environment.localStore.returnUrl);
  }

  storeReturnUrl = (returnUrl: string) => localStorage.setItem(environment.localStore.returnUrl, returnUrl);

  removeReturnUrl = () => localStorage.removeItem(environment.localStore.returnUrl);
}

class CustomToastrService {

  constructor(private toastrService: ToastrService) {

  }

  private readonly defaultTitle: string = 'Atenção';

  success = (message: string, title?: string) => setTimeout(() => this.toastrService.success(message, title || this.defaultTitle));

  error = (message: string, title?: string) => setTimeout(() => this.toastrService.error(message, title || this.defaultTitle));

  info = (message: string, title?: string) => setTimeout(() => this.toastrService.info(message, title || this.defaultTitle));

  warning = (message: string, title?: string) => setTimeout(() => this.toastrService.warning(message, title || this.defaultTitle));
}

class CustomSpinnerService {
  constructor(private spinnerService: NgxSpinnerService) {
  }

  show = () => this.spinnerService.show();

  hide = () => this.spinnerService.hide();

  start = () => this.show();

  stop = () => this.hide();
}

class DateUtils{
  toDate(value?: any) {
    if (!value) return null;
    let dateValue: Date;
    if (!(value instanceof Date)) {
      dateValue = new Date(value);
    }
    return dateValue;
  }

  getUTCJsonData(value?: any) {
    if (!value || value == null) return null;
    let date: Date = (value instanceof Date) ? value : this.toDate(value);
    let utc = new Date(date.getTime() + date.getTimezoneOffset() * 60000);
    return utc.toJSON();
  }

}

class CustomFormsService {

  date: DateUtils = new DateUtils();

  clearFormArray(formArray: UntypedFormArray){
    while(formArray.controls.length > 0){
      formArray.removeAt(0);
    }
  }

  fill(form: UntypedFormGroup, data: any) {
    for (let key in form.controls) {
      if (form.controls[key] instanceof UntypedFormArray) {
        continue;
      }
      if (form.controls[key] instanceof UntypedFormGroup) {
        this.fill(form.controls[key] as UntypedFormGroup, data[key]);
      }
      else {
        form.controls[key].setValue(data == null ? null : data[key] == null ? null : data[key]);
      }
    }
  }

  clear(form: UntypedFormGroup) {
    for (let key in form.controls) {
      if (form.controls[key] instanceof UntypedFormArray) {
        this.clearFormArray(form.controls[key] as UntypedFormArray);
        continue;
      }
      if (form.controls[key] instanceof UntypedFormGroup) {
        this.clear(form.controls[key] as UntypedFormGroup)
      }
      else {
        form.controls[key].setValue(null);
      }
    }
  }

  toJsonDate(form: UntypedFormGroup, ...props: string[]){
    for(let p in props){
      this.controlValueToJsonDate(form.controls[props[p]]);
    }
  }

  controlValueToJsonDate(control: AbstractControl){
    control.setValue(this.date.getUTCJsonData(control.value));
  }
}
